import { useEffect, useMemo, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleSearchComponent } from "../features/searchSlice";
import { toggleProfileComponent } from "../features/profileSlice";
import { addressesApi, etaApi, storeTimingApi } from "../api";

import { useWeather } from "../hooks/useWeather";
import {
  setAddressDetails,
  setEta,
  setLoading,
  setSavedAddresses,
  toggleIsAddressSliderOpen,
} from "../features/mapSlice";

//CUSTOM WEATHER ICONS
import DFewClouds from "../assets/icon components/weather icons/DFewClouds";
import DMist from "../assets/icon components/weather icons/DMist";
import DRain from "../assets/icon components/weather icons/DRain";
import DSun from "../assets/icon components/weather icons/DSun";
import NFewClouds from "../assets/icon components/weather icons/NFewClouds";
import NSun from "../assets/icon components/weather icons/NSun";
import Snow from "../assets/icon components/weather icons/Snow";
import Thunderstorm from "../assets/icon components/weather icons/Thunderstorm";
import TickLine from "../assets/icon components/TickLine";
import CrossLine from "../assets/icon components/CrossLine";

const Header = ({ transparent }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const userCart = useSelector((state) => state.apiCart.userCartItems);
  const { items } = useSelector((state) => state.cartItems);
  const { address, address2, addressType, coordinates, eta, savedAddresses } =
    useSelector((state) => state.googleMapLocation);
  const [isStoreOpen, setIsStoreOpen] = useState();

  // WEATHER DATA, WEATHER ICON MAPPING, WEATHER AQI MAPPING
  const locationInfo = useMemo(() => {
    return {
      latitude: coordinates.lat,
      longitude: coordinates.lng,
    };
  }, [coordinates]);
  const { weatherData } = useWeather(locationInfo);
  const aqiDescriptions = {
    1: "Good",
    2: "Fair",
    3: "Moderate",
    4: "Poor",
    5: "Poor",
  };
  const weatherIconComponents = {
    "01d": <DSun color="#f1edeb" width="35px" />,
    "01n": <NSun color="#f1edeb" width="35px" />,
    "02d": <DFewClouds color="#f1edeb" width="35px" />,
    "02n": <NFewClouds color="#f1edeb" width="35px" />,
    "03d": <DFewClouds color="#f1edeb" width="35px" />,
    "03n": <NFewClouds color="#f1edeb" width="35px" />,
    "04d": <DFewClouds color="#f1edeb" width="35px" />,
    "04n": <NFewClouds color="#f1edeb" width="35px" />,
    "09d": <DRain color="#f1edeb" width="35px" />,
    "09n": <DRain color="#f1edeb" width="35px" />,
    "10d": <DRain color="#f1edeb" width="35px" />,
    "10n": <DRain color="#f1edeb" width="35px" />,
    "11d": <Thunderstorm color="#f1edeb" width="35px" />,
    "11n": <Thunderstorm color="#f1edeb" width="35px" />,
    "13d": <Snow color="#f1edeb" width="35px" />,
    "13n": <Snow color="#f1edeb" width="35px" />,
    "50d": <DMist color="#f1edeb" width="35px" />,
    "50n": <DMist color="#f1edeb" width="35px" />,
  };

  const getAqiDescription = (aqiValue) => {
    return aqiDescriptions[aqiValue] || "Unknown";
  };
  const aqiDescription = getAqiDescription(weatherData?.aqi);

  // FETCH ETA
  const fetchETA = async (lat, lng) => {
    try {
      const response = await fetch(
        etaApi +
          `latitude=${lat || 28.48218399}&longitude=${lng || 77.09330629}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        dispatch(setEta(data));
        // console.log("Called eta")
      }
    } catch (error) {
      // console.log("eta error", error)
    }
  };

  useEffect(() => {
    if (!savedAddresses) {
      fetchETA(coordinates.lat, coordinates.lng);
    }
  }, [addressType, address, address2, coordinates]);

  useEffect(() => {
    fetchETA(coordinates.lat, coordinates.lng);
  }, [coordinates]);

  const fetchAddresses = async () => {
    dispatch(setLoading(true));
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addressesApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        dispatch(setSavedAddresses(data));
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch {
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getStoreTiming = async () => {
    try {
      const response = await fetch(storeTimingApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        // console.log("Store", data);
        setIsStoreOpen(data.store_open)
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchAddresses();
    getStoreTiming();
  }, []);

  //SET DEFAULT ADDRESS OR LATEST ADDRESS FROM SAVED ADDRESSES
  useEffect(() => {
    const defaultAddress = savedAddresses?.results?.find(
      (address) => address.is_default === true
    );
    dispatch(setLoading(true));

    if (defaultAddress) {
      const defaultAddressLat = parseFloat(defaultAddress.latitude);
      const defaultAddressLng = parseFloat(defaultAddress.longitude);
      dispatch(
        setAddressDetails({
          address: defaultAddress.address_line_1,
          address2: defaultAddress.address_line_2,
          addressType: defaultAddress.name,
          addressId: defaultAddress.id,
        })
      );
      fetchETA(defaultAddressLat, defaultAddressLng);
      return;
    }
    if (savedAddresses) {
      dispatch(
        setAddressDetails({
          address: savedAddresses?.results[0]?.address_line_1,
          address2: savedAddresses?.results[0]?.address_line_2,
          addressType: savedAddresses?.results[0]?.name,
          addressId: savedAddresses?.results[0]?.id,
        })
      );
      fetchETA(
        parseFloat(savedAddresses?.results[0]?.latitude),
        parseFloat(savedAddresses?.results[0]?.longitude)
      );
    }

    dispatch(setLoading(false));
  }, [savedAddresses]);

  return (
    <div className="">
      <header
        id="main-header"
        className={`relative px-4 md:px-10 md2:px-14 lg:px-10 xl:px-12 pt-[11px] pb-[10px] md:py-8 ${
          transparent
            ? "bg-transparent text-kitsaWhite"
            : "bg-formisBlack text-kitsaWhite"
        } transition-all duration-500`}
      >
        <div
          className={`flex justify-between xl:grid xl:grid-cols-4 items-center w-full lg:px-0`}
        >
          <div>
            <div className="text-sm font-bold leading-none font-[MestizaSans] mb-1">
              KITSA™ in
            </div>
            <div className="flex items-center gap-2 font-medium">
              <p className="text-sm text-kitsaGreen tracking-[0.38px] leading-none">
                {eta && eta.eta_minutes} minutes
              </p>

              <div className="bg-[#666666] rounded-full w-fit px-2 h-[20px] flex items-center justify-center gap-1">
                <span className="text-[10px] text-kitsaWhite leading-none">
                  {isStoreOpen ? "Store Open" : "Store Closed"}
                </span>
                {isStoreOpen ? (
                  <TickLine color="#f1edeb" width="9" />
                ) : (
                  <CrossLine color="#f1edeb" width="9" />
                )}
              </div>
            </div>

            {address && addressType ? (
              <div
                onClick={() => dispatch(toggleIsAddressSliderOpen(true))}
                className="flex items-center gap-1"
              >
                <p className="text-sm font-normal">
                  <span className="font-medium capitalize">
                    {addressType} -{" "}
                  </span>
                  {address.length > 10 ? address.slice(0, 10) + "..." : address}
                </p>
                <TiArrowSortedDown />
              </div>
            ) : (
              <p
                onClick={() => dispatch(toggleIsAddressSliderOpen(true))}
                className="font-medium flex items-center text-sm"
              >
                Add Address
                <TiArrowSortedDown className="ml-2" />
              </p>
            )}
          </div>

          {/* Desktop Hovered Menu Items */}
          <ul className="hidden xl:flex justify-center font-[500] gap-2 text-[15px] xl:text-[17px] col-span-2">
            <li
              className="header-menu"
              onClick={() => navigate("/all-products")}
            >
              Shop
            </li>
            <li className="header-menu" onClick={() => navigate("/contact")}>
              Contact
            </li>
            <li className="header-menu" onClick={() => navigate("/blog")}>
              Blogs
            </li>
          </ul>

          <div className="flex items-center justify-center text-2xl gap-4 lg:gap-7 w-fit ml-auto">
            {/* Search Icon Desktop */}
            <svg
              className="w-6 hidden xl:block cursor-pointer"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => dispatch(toggleSearchComponent())}
            >
              <path
                strokeLinecap="round"
                d="m21 21-3.636-3.636m0 0A9 9 0 1 0 4.636 4.636a9 9 0 0 0 12.728 12.728Z"
              ></path>
            </svg>
            {/* Profile Icon Desktop */}
            <svg
              className="w-6 cursor-pointer hidden xl:block"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => dispatch(toggleProfileComponent())}
            >
              <rect
                width="10.5"
                height="10.5"
                x="6.75"
                y="1.75"
                rx="5.25"
              ></rect>
              <path
                strokeLinecap="round"
                d="M12 15.5c1.5 0 4 .333 4.5.5.5.167 3.7.8 4.5 2 1 1.5 1 2 1 4m-10-6.5c-1.5 0-4 .333-4.5.5-.5.167-3.7.8-4.5 2-1 1.5-1 2-1 4"
              ></path>
            </svg>
            {/* Cart Icon Desktop */}
            <div className=" hidden xl:block relative">
              {!isAuthenticated && items?.length !== 0 && (
                <span className="absolute -right-2 -top-5 text-[12px] font-semibold">
                  {items?.length}
                </span>
              )}
              {isAuthenticated && userCart?.length !== 0 && (
                <span className="absolute -right-2 -top-5 text-[12px] font-semibold">
                  {userCart?.length}
                </span>
              )}
              <svg
                className="w-6 cursor-pointer"
                viewBox="0 0 24 24"
                stroke="currentColor"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => navigate("/cart")}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M1 1h.5v0c.226 0 .339 0 .44.007a3 3 0 0 1 2.62 1.976c.034.095.065.204.127.42l.17.597m0 0 1.817 6.358c.475 1.664.713 2.496 1.198 3.114a4 4 0 0 0 1.633 1.231c.727.297 1.592.297 3.322.297h2.285c1.75 0 2.626 0 3.359-.302a4 4 0 0 0 1.64-1.253c.484-.627.715-1.472 1.175-3.161l.06-.221c.563-2.061.844-3.092.605-3.906a3 3 0 0 0-1.308-1.713C19.92 4 18.853 4 16.716 4H4.857ZM12 20a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm8 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                ></path>
              </svg>
            </div>

            <Link to="/weather" className="flex items-center gap-[18px]">
              <div>{weatherIconComponents[weatherData?.icon]}</div>
              <div className="flex flex-col gap-[4px] leading-none col-span-2">
                <p className="text-lg flex gap-[1px] font-medium leading-none">
                  {Math.round(weatherData?.temperature)}
                  <span className="text-[10px] relative top-[2px]">℃</span>
                </p>
                <p className="text-[11px] text-kitsaGreen font-[800] font-[MestizaSans]">
                  AQI - {aqiDescription}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;




  // useEffect(() => {
  //   const currentDate = new Date();
  //   const hours = currentDate.getHours();
  //   const mins = currentDate.getMinutes();

  //   const isOpen =
  //     (hours > 11 || (hours === 11 && mins >= 30)) &&
  //     (hours < 20 || (hours === 20 && mins <= 15));
  //   setIsStoreOpen(isOpen);
  // }, []);