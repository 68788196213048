import { lazy, Suspense, useEffect, useRef, useState } from "react";
import dosage from "../assets/icons/dosage.svg";
import salt from "../assets/icons/salt-composition.svg";
import certified from "../assets/icons/certified.svg";
import forAlt from "../assets/icons/forAlt.svg";
import concentration from "../assets/icons/concentration.svg";
import MobileFooter from "../components/MobileFooterMenu";
import discount from "../assets/icons/discount.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelOrderApi,
  orderOptionsApi,
  orderStatusApi,
  userUpdateOrderApi,
} from "../api";
import { setUpdates } from "../features/apiCartSlice";
import Accordian from "../components/Alternate Page Components/Accordian";
import EtaIcon from "../assets/icon components/Eta";
import ArrowRight from "../assets/icon components/ArrowRight";
import Header from "../components/Header";
import TickLine from "../assets/icon components/TickLine";
import CrossLine from "../assets/icon components/CrossLine";
import { toast } from "sonner";

const Search = lazy(() => import("../components/Search"));
const Profile = lazy(() => import("../components/Profile"));
const AddressSlider = lazy(() => import("../components/AddressSlider"));
const SurgeSlider = lazy(() => import("../components/SurgeSlider"));

const Alternates = () => {
  const navigate = useNavigate();
  const { updates } = useSelector((state) => state.apiCart);
  const dispatch = useDispatch();

  const getOrderUpdates = async () => {
    const access = localStorage.getItem("accessToken");
    const orderCode = localStorage.getItem("orderCode");
    try {
      const response = await fetch(orderStatusApi + orderCode, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        dispatch(setUpdates(data));
        // console.log("updates", data);
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  const intervalRef = useRef(null);
  useEffect(() => {
    if (localStorage.getItem("orderCode") !== "null") {
      getOrderUpdates();
      intervalRef.current = setInterval(() => {
        getOrderUpdates();
      }, 20000);
    }

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (
      updates?.status === "IN PROGRESS" ||
      updates?.status === "INVOICE GENERATED" ||
      updates?.status === "ALL AVAILABLE" ||
      updates?.status === "FULLY APPROVED" ||
      updates?.status === "RIDER ASSIGNED" ||
      updates?.status === "REACHED PICKUP LOCATION" ||
      updates?.status === "START TO PICKUP" ||
      updates?.status === "ORDER PICKED UP" ||
      updates?.status === "REACHED DELIVERY LOCATION" ||
      updates?.status === "DELIVERED" ||
      updates?.status === "RETURNED" ||
      updates?.status === "OUT OF SERVICEABLE AREA"
    )
      navigate("/track-order");
    if (
      updates?.status === "CANCELLED DUE TO UNAVAILABILITY" ||
      updates?.status === "CANCELLED-OTC" ||
      updates?.status === "CANCELLED BY USER"
    ) {
      navigate("/");
      toast("Your Order has been Cancelled");
    }
  }, [updates]);

  return (
    <div className="text-kitsaWhite bg-formisBlack min-h-[100dvh] pb-56 scrollbar-hide">
      <div className="h-12 bg-formisBlack font-bold text-xs flex items-center justify-center">
        <p>A few of your essentials are not available for immediate delivery</p>
      </div>

      <Header />

      <div className="pt-8">
        <div className="px-3 text-xs font-bold pb-4">
          <p className="mb-2">We have SWITCH options for you</p>
          <p>Switch Options are products that have 100% same:</p>
        </div>

        <div className="px-3 flex items-center justify-between pt-[2px] pb-[6px] mb-7">
          <div className="shadow-peachBox w-[110px] h-[91px] px-2 pt-[14px] rounded-[10px] shrink-0">
            <img src={salt} alt="Salt Composition" className="mb-5" />
            <p className="text-[11px] font-bold">Salt and Composition</p>
          </div>
          <div className="shadow-peachBox w-[110px] h-[91px] px-2 pt-[14px] rounded-[10px] shrink-0">
            <img src={dosage} alt="Salt Composition" className="mb-2" />
            <p className="text-[11px] font-bold">Pattern and Dosage</p>
          </div>
          <div className="shadow-peachBox w-[110px] h-[91px] px-2 pt-[14px] rounded-[10px] shrink-0">
            <img
              src={concentration}
              alt="Salt Composition"
              className="mb-2 w-7"
            />
            <p className="text-[11px] font-bold">Product Concentration</p>
          </div>
        </div>

        <div className="flex items-center px-3 justify-between mb-12">
          <div className="flex items-center gap-2">
            <img src={certified} alt="Cert" className="w-[34px]" />
            <p className="text-xs font-bold">Verified by Experts</p>
          </div>
          <div className="flex items-center gap-2">
            <img src={forAlt} alt="Cert" className="w-[34px]" />
            <p className="text-xs font-bold">FORMIS® forAlt Engine</p>
          </div>
        </div>

        {/* AVAILABLE ITEMS */}
        {updates?.order_items.some((item) => item.status === "AVAILABLE") && (
          <div className="shadow-peachBox mx-3 rounded-[8px] px-3 mb-8">
            <Accordian
              data={updates?.order_items}
              status="AVAILABLE"
              title="Available essentials from your cart"
              desc="Great news! These items are in stock and ready for despatch."
            >
              <div className="pb-3">
                <div className="flex flex-col gap-4 pt-4">
                  {updates?.order_items
                    .filter((item) => item.status === "AVAILABLE")
                    .map((item, index) => (
                      <ProductCardAvailable key={index} product={item} />
                    ))}
                </div>
              </div>
            </Accordian>
          </div>
        )}

        {/* UNAVAILABLE ITEMS */}
        {updates?.order_items.some((item) => item.status === "UNAVAILABLE") && (
          <div className="shadow-peachBox mx-3 rounded-[8px] px-3 mb-8">
            <Accordian
              data={updates?.order_items}
              status="UNAVAILABLE"
              title="Unavailable essentials from your cart"
              desc="Some items are currently out of stock. You can choose to proceed without them or explore alternatives."
            >
              <div className="pb-3">
                <div className="flex flex-col gap-4 pt-4">
                  {updates?.order_items
                    .filter((item) => item.status === "UNAVAILABLE")
                    .map((item, index) => (
                      <ProductCardAvailable key={index} product={item} />
                    ))}
                </div>
              </div>
            </Accordian>
          </div>
        )}

        {/* ALTERNATE PRODUCTS */}
        {updates?.order_items.some(
          (item) => item.status === "ALTERNATE SUGGESTED"
        ) && (
          <div className="shadow-peachBox mx-3 rounded-[8px] px-3 mb-8">
            <Accordian
              open={true}
              data={updates?.order_items}
              status="ALTERNATE SUGGESTED"
              title="Rapid Delivery Alternatives"
              desc="Faster alternatives are available! Need to stick to preferred brands. Select schedule."
            >
              <div className="pb-3 flex flex-col gap-8">
                {updates?.order_items
                  .filter((item) => item.alternate_product !== null)
                  .map((item, index) => (
                    <AlternateCard2 num={index + 1} key={index} data={item} />
                  ))}
              </div>
            </Accordian>
          </div>
        )}
      </div>

      <div className="fixed z-[30] bottom-0 w-full left-0">
        <AlternateButton updates={updates} />
        <MobileFooter />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <AddressSlider />
        <Search />
        <Profile />
        <SurgeSlider />
      </Suspense>
    </div>
  );
};

export default Alternates;

const AlternateButton = ({ updates }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);

  // useEffect(() => {
  //   console.log("Alternate Options", options);
  // }, [options]);

  const getOrderOptions = async () => {
    const access = localStorage.getItem("accessToken");
    const orderCode = localStorage.getItem("orderCode");
    try {
      const response = await fetch(orderOptionsApi + orderCode, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setOptions(data);
        // console.log("OPTIONS", data);
      } else {
      }
    } catch {}
  };

  useEffect(() => {
    getOrderOptions();
  }, []);

  const updateOrderItems = async (action) => {
    const access = localStorage.getItem("accessToken");
    const orderCode = localStorage.getItem("orderCode");
    try {
      const response = await fetch(userUpdateOrderApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          user_action: action,
          order_code: orderCode,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        // console.log("USER ACTION", data);
        navigate("/track-order");
      } else {
      }
    } catch (err) {}
  };

  const cancelOrder = async () => {
    const access = localStorage.getItem("accessToken");
    const orderCode = localStorage.getItem("orderCode");
    try {
      const response = await fetch(cancelOrderApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          order_code: orderCode,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (data.status) {
          navigate("/order-cancelled");
          dispatch(setUpdates(null))
        } else toast("Something Went Wrong!");
      } else {
        toast("Something Went Wrong!");
      }
    } catch (err) {}
  };

  const optionsMap = {
    "Proceed Please": "Send Available Alternates",
    "Proceed Without Alternate": "Send Only Available",
    "Proceed With Alternate": "Send Available & Alternates",
    "Send Just Available": "Send Only Available",
  };

  return (
    <div className="flex items-center justify-center gap-2 px-3 bg-formisBlack py-2">
      {options?.options?.map((option, index) => (
        <button
          key={index}
          onClick={() => updateOrderItems(option)}
          onTouchStart={(e) =>
            e.currentTarget.classList.add("scale-95", "opacity-70")
          }
          onTouchEnd={(e) =>
            e.currentTarget.classList.remove("scale-95", "opacity-70")
          }
          className="h-10 bg-kitsaGreen rounded-full flex items-center justify-center gap-1 px-1 transition-all"
        >
          {option === "Proceed With Alternate" ? (
            <div className="flex items-center justify-center my-2">
              <div className="rotate-90 w-fit">
                <ArrowRight width="15" color="#f1edeb" />
              </div>
              <div className="-ml-2 -rotate-90 w-fit">
                <ArrowRight width="15" color="#f1edeb" />
              </div>
            </div>
          ) : (
            <TickLine color="#f1edeb" width="15" />
          )}
          <p className="text-[10px] font-bold text-kitsaWhite">
            {optionsMap[option]}
          </p>
        </button>
      ))}

      <button
        onClick={cancelOrder}
        onTouchStart={(e) =>
          e.currentTarget.classList.add("scale-95", "opacity-70")
        }
        onTouchEnd={(e) =>
          e.currentTarget.classList.remove("scale-95", "opacity-70")
        }
        className="size-10 bg-kitsaGreen rounded-full flex items-center justify-center transition-all"
      >
        <CrossLine color="#f1edeb" width="15" />
      </button>
    </div>
  );
};

const AlternateCard2 = ({ num, data }) => {
  const { eta } = useSelector((state) => state.googleMapLocation);

  return (
    <div className="">
      <hr className="border-b-[0.5px] mb-2" />
      <p className="text-xs font-bold bg-kitsaGreen text-kitsaWhite w-fit px-3 py-1 rounded-[8px] mb-4">
        Option {num}
      </p>
      <div>
        <div className="flex items-center border border-kitsaWhite gap-2 px-[5px] py-[2px] rounded-full w-fit mb-3">
          <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
            Preferred - Out of stock
          </p>
          <EtaIcon color="#f1edeb" />
        </div>
        <div>
          <ProductCardAlternate data={data} product={data.product} />
        </div>
      </div>

      <div className="flex items-center justify-center my-2">
        <div className="rotate-90 w-fit">
          <ArrowRight width="20" color="#f1edeb" />
        </div>
        <div className="-rotate-90 w-fit">
          <ArrowRight width="20" color="#f1edeb" />
        </div>
      </div>

      <div>
        <div className="flex items-center border border-kitsaWhite gap-2 px-[5px] py-[2px] rounded-full w-fit mb-3">
          <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
            Alternate - {eta.eta_minutes} mins
          </p>
          <EtaIcon color="#f1edeb" />
        </div>
        <div>
          <ProductCardAlternate
            data={data.alternate_product}
            product={data.alternate_product.product}
          />
        </div>
      </div>
    </div>
  );
};

const ProductCardAvailable = ({ product }) => {
  const navigate = useNavigate();
  return (
    <div className="flex gap-4 justify-between">
      <div className="flex gap-4">
        <div
          onClick={() => navigate(`/product/${product.product.slug_field}`)}
          className="bg-white rounded-[10px] relative shrink-0 h-[77px] w-[78px]"
        >
          <img
            src={
              product?.product.primary_image_url ===
              "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? "https://media-hosting.imagekit.io//a3385a43077f4e0e/Image%20Not%20Available.png?Expires=1833358862&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=h-gValwZSoRKUBi9iEHAzKOKdwPlgYLNYSi5K7sAEH2knqMt4gpgaf~GJytsoI9gQemKj-5mIaJ4SPminI9Ts4j4esZ4j-udW3LgKjxS5Vn7goq6Z4bhS~5hzKy4uzSjttW1KAoemJvrM3em5fiBlImWlXQ6lOkyt79rhvtE11KwbRFH2w2pbdXPemroPpZxgHLRAAJ6EEsWL-psJxPosRF6LZz5giAWuUNGeem~P0IieT38Qlzvb6~nyJ96og4-XODPBV46s6vk3amWOa20pBrPSDP0X6a7Tjwpo9vunyMapTz9U0ucJqtk~c6yn9kD-YcgMIlQtPJmOG8AbIdtSA__"
                : product.product.primary_image_url
            }
            loading="lazy"
            alt="image"
            className="h-[77px] w-[78px] rounded-[10px]"
          />
          <div className="h-[23px] w-[19px] absolute -top-[7px] z-10 -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute -top-1 -left-[2.5px] h-[28px] w-[23px] flex items-center justify-center">
              <p className="text-[8px] text-formisBlack font-bold leading-none">
                {parseInt(product?.discount_percentage)}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        <div className="shrink">
          <p className="text-xs font-bold mb-1">{product?.product.name}</p>
          <div className="flex items-center gap-1 mb-2">
            <div className="bg-kitsaWhite rounded-full">
              <span className="capitalize text-[10px] font-bold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-kitsaWhite text-formisBlack border border-kitsaWhite">
                {product?.product.packaging}
              </span>
            </div>
          </div>

          <div className="text-[10px] font-medium">
            <p>
              {product?.product.salt_composition !== "NA"
                ? product.product.salt_composition
                : ""}
            </p>
          </div>
        </div>
      </div>

      <div className="">
        <div className="bg-kitsaGreen text-kitsaWhite flex items-center justify-center w-full rounded-[8px] py-[2px] mb-4">
          <input
            value={product?.quantity}
            className="text-sm font-bold bg-transparent outline-none w-[40px] text-center"
            readOnly
          />
        </div>

        <div className="flex items-center gap-1 font-bold place-self-end">
          <p className="text-[12px]">₹{product?.selling_price_at_time}</p>
          <span className="text-[10px]">
            <s>₹{product?.product.mrp}</s>
          </span>
        </div>
      </div>
    </div>
  );
};

const ProductCardAlternate = ({ product, data }) => {
  const navigate = useNavigate();
  return (
    <div className="flex gap-4 justify-between">
      <div className="flex gap-4">
        <div
          onClick={() => navigate(`/product/${product.slug_field}`)}
          className="bg-white rounded-[10px] relative shrink-0 h-[77px] w-[78px]"
        >
          <img
            src={
              product?.primary_image_url ===
              "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? "https://media-hosting.imagekit.io//a3385a43077f4e0e/Image%20Not%20Available.png?Expires=1833358862&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=h-gValwZSoRKUBi9iEHAzKOKdwPlgYLNYSi5K7sAEH2knqMt4gpgaf~GJytsoI9gQemKj-5mIaJ4SPminI9Ts4j4esZ4j-udW3LgKjxS5Vn7goq6Z4bhS~5hzKy4uzSjttW1KAoemJvrM3em5fiBlImWlXQ6lOkyt79rhvtE11KwbRFH2w2pbdXPemroPpZxgHLRAAJ6EEsWL-psJxPosRF6LZz5giAWuUNGeem~P0IieT38Qlzvb6~nyJ96og4-XODPBV46s6vk3amWOa20pBrPSDP0X6a7Tjwpo9vunyMapTz9U0ucJqtk~c6yn9kD-YcgMIlQtPJmOG8AbIdtSA__"
                : product.primary_image_url
            }
            loading="lazy"
            alt="image"
            className="h-[77px] w-[78px] rounded-[10px]"
          />
          <div className="h-[23px] w-[19px] absolute -top-[7px] z-10 -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute -top-1 -left-[2.5px] h-[28px] w-[23px] flex items-center justify-center">
              <p className="text-[8px] text-formisBlack font-bold leading-none">
                {parseInt(data.discount_percentage)}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        <div className="shrink">
          <p className="text-xs font-bold mb-1">{product.name}</p>
          <div className="flex items-center gap-1 mb-2">
            <div className="bg-kitsaWhite rounded-full">
              <span className="capitalize text-[10px] font-bold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-kitsaWhite text-formisBlack border border-kitsaWhite">
                {product.packaging}
              </span>
            </div>
          </div>

          <div className="text-[10px] font-medium">
            <p>
              {product?.salt_composition !== "NA"
                ? product?.salt_composition
                : ""}
            </p>
          </div>
        </div>
      </div>

      <div className="">
        <div className="bg-kitsaGreen text-kitsaWhite flex items-center justify-center w-full rounded-[8px] py-[2px] mb-4">
          <input
            value={data?.quantity}
            className="text-sm font-bold bg-transparent outline-none w-[40px] text-center"
            readOnly
          />
        </div>

        <div className="flex items-center gap-1 font-bold place-self-end">
          <p className="text-[12px]">₹{data?.selling_price_at_time}</p>
          <span className="text-[10px]">
            <s>₹{data?.mrp}</s>
          </span>
        </div>
      </div>
    </div>
  );
};

// const updates = {
//   id: 97,
//   user: "4802faa1-9719-4df3-9936-0afe11616879",
//   total: "818.64",
//   discount: "63.36",
//   gst_amount: "134.54",
//   cgst_amount: "67.28",
//   sgst_amount: "67.28",
//   shipping_address: {
//     id: "f03511a9-cf5d-4f6d-b5f3-6b50f3b02d6b",
//     user: "4802faa1-9719-4df3-9936-0afe11616879",
//     address_person: "MYSELF",
//     name: "Hotel",
//     address_line_1: "guru nanak",
//     address_line_2: "Sector 24",
//     city: "Gurugram",
//     state: "Haryana",
//     latitude: "28.494931",
//     longitude: "77.100140",
//     postal_code: "122022",
//     is_default: false,
//     nearby_landmark: "e2fwqf",
//     contact_name: "bhanu",
//     contact_number: "7058000029",
//   },
//   total_price: "882.00",
//   status: "ORDER MODIFIED",
//   created_at: "2025-02-06T14:24:29.373235+05:30",
//   updated_at: "2025-02-06T14:25:15.141381+05:30",
//   fast_pass_order_code: "ZETA-0971-7861",
//   fast_pass_order_id: 6864,
//   applied_coupon: null,
//   coupon_discount: "0.00",
//   order_items: [
//     {
//       id: 169,
//       user: "4802faa1-9719-4df3-9936-0afe11616879",
//       order: 97,
//       product: {
//         name: "Trisa Perfect White Toothbrush Medium",
//         manufacturer: "Rama Vision Limited",
//         salt_composition: "NA",
//         description:
//           "Trisa Perfect White Toothbrush can improve your dental health by removing plaque in more significant quantities and allowing toothpaste and brush bristles to reach more of the tooth.Uses:\n\nIt removes plaque and bacteria effectively\n\nProduct Specifications and Features:\n\nThe soft bristles gently stimulate the gums whilst cleaning the surface of the teeth at the same time\n\n\nShorter Cceaning bristles reliably remove plaque from the tooth surfaces\n\n\nErgonomic handle with a non-slip surface\n\n\nSoft microstructures on the back help remove bacteria from the tongue\n\nDirections for Use:Brush your teeth twice daily.Safety Information:\n\nRead the label carefully before use",
//         primary_image_url: "https://medicinedata.in/o/DR055225_1.jpg",
//         category: "Skin, hair & oral care",
//         packaging: "packet of 1 Toothbrush",
//         package: "Packet",
//         package_quantity: "1",
//         product_form: "Toothbrush",
//         mrp: "329.00",
//         prescription_required: "NA",
//         slug_field: "trisa-perfect-white-toothbrush-medium",
//         sku_id: "DR546319",
//         fast_pass_id: 937025,
//         product_id: "PID2021389364",
//       },
//       quantity: 1,
//       mrp: "329.00",
//       gst_percentage: "18.00",
//       cgst_percentage: "9.00",
//       sgst_percentage: "9.00",
//       gst_amount: "50.19",
//       cgst_amount: "25.10",
//       sgst_amount: "25.10",
//       discount_amount: "26.32",
//       discount_percentage: "8.00",
//       selling_price_at_time: "302.68",
//       status: "ALTERNATE SUGGESTED",
//       alternate_product: {
//         id: 15,
//         product: {
//           name: "Boost Powder Refill Pack Malt (1kg)",
//           manufacturer: "Hindustan Unilever Ltd",
//           salt_composition: "",
//           description: "NA",
//           primary_image_url:
//             "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png",
//           category: "NA",
//           packaging: "Box of 1kg Powder",
//           package: "Box of 1kg Powder",
//           package_quantity: "Box of 1kg Powder",
//           product_form: "NA",
//           mrp: "450.00",
//           prescription_required: "NA",
//           slug_field: "boost-powder-refill-pack-malt-1kg",
//           sku_id: "SKU_2020100111011",
//           fast_pass_id: 27718,
//           product_id: "PID2021025896",
//         },
//         quantity: 1,
//         mrp: "450.00",
//         gst_percentage: "18.00",
//         cgst_percentage: "9.00",
//         sgst_percentage: "9.00",
//         gst_amount: "68.64",
//         cgst_amount: "34.32",
//         sgst_amount: "34.32",
//         discount_amount: "36.00",
//         discount_percentage: "8.00",
//         selling_price_at_time: "414.00",
//       },
//       total_price: "302.68",
//       user_accepted_alternate: "NO ACTION TAKEN",
//     },
//     {
//       id: 170,
//       user: "4802faa1-9719-4df3-9936-0afe11616879",
//       order: 97,
//       product: {
//         name: "Haslab Bico 41 Biochemic Compound Tablet",
//         manufacturer: "HASLAB",
//         salt_composition: "NA",
//         description:
//           "Haslab Bico 41 Biochemic Compound Tablet helps to restore the balance of minerals in the body and improve overall health. It helps to provide relief from ulcers or blisters inside the mouth, and on the tongue in feeding children or adults.",
//         primary_image_url: "https://medicinedata.in/o/DR030771_1.jpg",
//         category: "Skin, hair & oral care",
//         packaging: "bottle of 20 gm Tablet",
//         package: "Bottle",
//         package_quantity: "20 gm",
//         product_form: "Tablet",
//         mrp: "75.00",
//         prescription_required: "NA",
//         slug_field: "haslab-bico-41-biochemic-compound-tablet",
//         sku_id: "DR521865",
//         fast_pass_id: 943079,
//         product_id: "PID2021395418",
//       },
//       quantity: 1,
//       mrp: "75.00",
//       gst_percentage: "18.00",
//       cgst_percentage: "9.00",
//       sgst_percentage: "9.00",
//       gst_amount: "11.44",
//       cgst_amount: "5.72",
//       sgst_amount: "5.72",
//       discount_amount: "2.25",
//       discount_percentage: "3.00",
//       selling_price_at_time: "72.75",
//       status: "ALTERNATE SUGGESTED",
//       alternate_product: {
//         id: 16,
//         product: {
//           name: "Horlicks Junior Horlicks Health and Nutrition Drink Vanilla (500gm)",
//           manufacturer: "Hindustan Unilever Ltd",
//           salt_composition: "",
//           description: "NA",
//           primary_image_url:
//             "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png",
//           category: "NA",
//           packaging: "Jar of 500gm Powder",
//           package: "Jar of 500gm Powder",
//           package_quantity: "Jar of 500gm Powder",
//           product_form: "NA",
//           mrp: "288.00",
//           prescription_required: "NA",
//           slug_field:
//             "horlicks-junior-horlicks-health-and-nutrition-drink-vanilla-500gm",
//           sku_id: "SKU_2020100111011",
//           fast_pass_id: 68870,
//           product_id: "PID2021063560",
//         },
//         quantity: 1,
//         mrp: "288.00",
//         gst_percentage: "18.00",
//         cgst_percentage: "9.00",
//         sgst_percentage: "9.00",
//         gst_amount: "43.93",
//         cgst_amount: "21.97",
//         sgst_amount: "21.97",
//         discount_amount: "23.04",
//         discount_percentage: "8.00",
//         selling_price_at_time: "264.96",
//       },
//       total_price: "72.75",
//       user_accepted_alternate: "NO ACTION TAKEN",
//     },
//     {
//       id: 171,
//       user: "4802faa1-9719-4df3-9936-0afe11616879",
//       order: 97,
//       product: {
//         name: "Bentodent Clean Bamboo Tongue Cleaner",
//         manufacturer: "Indian Dental Organization Private Limited",
//         salt_composition: "NA",
//         description:
//           "Bentodent Clean Bamboo Tongue Cleaner is made with pure bamboo wood and is biodegradable. It can help clean the tongue and is broad to help cover the maximum tongue area.",
//         primary_image_url: "https://medicinedata.in/o/DR055641_1.jpg",
//         category: "Skin, hair & oral care",
//         packaging: "packet of 1 Unit",
//         package: "Packet",
//         package_quantity: "1",
//         product_form: "Unit",
//         mrp: "79.00",
//         prescription_required: "NA",
//         slug_field: "bentodent-clean-bamboo-tongue-cleaner",
//         sku_id: "DR546735",
//         fast_pass_id: 946805,
//         product_id: "PID2021399145",
//       },
//       quantity: 1,
//       mrp: "79.00",
//       gst_percentage: "18.00",
//       cgst_percentage: "9.00",
//       sgst_percentage: "9.00",
//       gst_amount: "12.05",
//       cgst_amount: "6.03",
//       sgst_amount: "6.03",
//       discount_amount: "2.37",
//       discount_percentage: "3.00",
//       selling_price_at_time: "76.63",
//       status: "AVAILABLE",
//       alternate_product: null,
//       total_price: "76.63",
//       user_accepted_alternate: "NO ACTION TAKEN",
//     },
//     {
//       id: 172,
//       user: "4802faa1-9719-4df3-9936-0afe11616879",
//       order: 97,
//       product: {
//         name: "Basic Ayurveda Mouth Gel for LA",
//         manufacturer: "Basic Ayurveda",
//         salt_composition: "NA",
//         description:
//           "Basic Ayurveda Mouth Gel for LA is an ayurvedic formulation that helps with mouth ulcers. It provides instant relief from pain and irritation.",
//         primary_image_url: "https://medicinedata.in/o/DR014196_1.jpg",
//         category: "Skin, hair & oral care",
//         packaging: "bottle of 10 gm Gel",
//         package: "Bottle",
//         package_quantity: "10 gm",
//         product_form: "Gel",
//         mrp: "65.00",
//         prescription_required: "NA",
//         slug_field: "basic-ayurveda-mouth-gel-for-la",
//         sku_id: "DR505290",
//         fast_pass_id: 947582,
//         product_id: "PID2021399922",
//       },
//       quantity: 1,
//       mrp: "65.00",
//       gst_percentage: "18.00",
//       cgst_percentage: "9.00",
//       sgst_percentage: "9.00",
//       gst_amount: "9.92",
//       cgst_amount: "4.96",
//       sgst_amount: "4.96",
//       discount_amount: "1.95",
//       discount_percentage: "3.00",
//       selling_price_at_time: "63.05",
//       status: "AVAILABLE",
//       alternate_product: null,
//       total_price: "63.05",
//       user_accepted_alternate: "NO ACTION TAKEN",
//     },
//   ],
//   user_action_required: true,
//   user_action_taken: false,
//   user_action: "NO ACTION TAKEN",
//   invoice_url: null,
//   rider_name: null,
//   rider_mobile: null,
//   payment_completed: false,
//   payment_method: null,
//   rider_otp: null,
//   payment_link: null,
// };
