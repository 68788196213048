import { useState, useEffect } from "react";
import CloseIcon from "../../assets/icon components/CloseIcon";
import logo from "../../assets/kitsa-logo-green.png";
import safari from "../../assets/install/safari.png";
import chrome from "../../assets/install/chrome.png";
import addToHome from "../../assets/install/addtohome.png";

const InstallPWAPopup = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showIosMessage, setShowIosMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      showModalPopup();
    }, 3500);

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setShowModal(true);
    };

    const showModalPopup = () => {
      const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        navigator.standalone;

      if (isStandalone) {
        return; // Exit early if the PWA is already installed
      }

      if (isIos && !isStandalone) {
        setShowIosMessage(true);
        setShowModal(true); // Show modal for iOS users
      }

      window.addEventListener("beforeinstallprompt", () => {
        handleBeforeInstallPrompt();
        setShowModal(true);
      });
    };

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
      clearTimeout(timer);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setDeferredPrompt(null);
        setShowModal(false); // Close modal after install
      });
    }
  };

  return (
    <>
      {showModal && (
        <div className="z-[1000] fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center bg-formisBlack/70 px-3">
          <button onClick={() => setShowModal(false)} className="mb-5">
            <CloseIcon color="#f1edeb" />
          </button>
          <div className="bg-formisWhite rounded-[8px] w-full">
            <div className="flex flex-col mb-3 px-2 pt-4">
              <img src={logo} alt="KITSA™" className="w-28 mb-2" />
            </div>

            {deferredPrompt ? (
              <div>
                <p className="text-sm font-medium px-2 mb-2">
                  Install our app for faster orders, real-time updates, and
                  seamless shopping – all in one place!
                </p>
                <ul className="text-sm font-medium px-2 mb-4">
                  <li>✅ Quick Ordering</li>
                  <li>✅ Exclusive Deals</li>
                  <li>✅ Track Orders Instantly</li>
                </ul>
                <div className="flex justify-between border-t border-formisBlack/50 text-sm">
                  <button className="w-[50%] py-2 border-r border-formisBlack/50">
                    Cancel
                  </button>
                  <button className="w-[50%] py-2" onClick={handleInstallClick}>
                    Install Now
                  </button>
                </div>
              </div>
            ) : (
              showIosMessage && (
                <div className="px-2 pb-4">
                  <h2 className="text-sm font-bold text-formisBlack">
                    Install Our App
                  </h2>
                  <p className="text-sm font-medium mb-2">
                    📲 <strong>iOS Users:</strong> <br />
                    Tap <strong>Share</strong> →
                    <strong> Add to Home Screen</strong> to install.
                  </p>

                  <div className="text-sm font-bold">
                    <div className="mb-3">
                      <p>Safari:</p>
                      <img src={safari} alt="instructions for safari" />
                    </div>
                    <div className="mb-3">
                      <p>Chrome:</p>
                      <img src={chrome} alt="instructions for chrome" />
                    </div>
                    <div className="flex flex-col">
                      <p>Click on add to home screen:</p>
                      <img src={addToHome} alt="add to home screen" />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InstallPWAPopup;
