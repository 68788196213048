import { useEffect, useState } from "react";
import splashMobile from "../assets/splash-mobile.gif"
import splashDesktop from "../assets/splash-desktop.gif"

const SplashPage = () => {
  const [translate, setTranslate] = useState(true);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTranslate(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHidden(true);
    }, 3500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (hidden === false) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.height = "100vh";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.height = "auto";
      document.body.style.overflow = "auto";
    };
  }, [hidden]);

  return (
    <div
      className={`transition-all duration-500 overflow-hidden h-[100dvh] w-screen absolute top-0 left-0 bg-formisBlack z-50 ${
        !translate ? "opacity-0" : "opacity-100"
      } ${hidden && "hidden"} flex flex-col items-center justify-center`}
    >
      <div className="h-full w-full">
        <img src={splashMobile} alt="FORMIS" className="lg:hidden w-full h-full" />
        <img src={splashDesktop} alt="FORMIS" className="hidden lg:block w-full h-full" />
      </div>
    </div>
  );
};

export default SplashPage;
