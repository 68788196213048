import { useNavigate } from "react-router-dom";
import call from "../assets/icons/call.svg";
import login from "../assets/icons/login.svg";
import retry from "../assets/icons/retry.svg";
import kitsaFavicon from "../assets/kitsa-favicon.ico";
import { useEffect, useState } from "react";
import { addressesApi, addToCartApi, sendOtpApi, verifyOtpApi } from "../api";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddressDetails,
  setAddressStep,
  setCoordinates,
  toggleIsAddressSliderOpen,
} from "../features/mapSlice";
import useCartDetails from "../hooks/useCartDetails";

const Login = () => {
  const navigate = useNavigate();
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [phoneSubmitData, setPhoneSubmitData] = useState();
  const [loading, setLoading] = useState(false);

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [mobile, setMobile] = useState("");

  const handlePhoneSubmit = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(sendOtpApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile_number: mobile,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        // console.log(data);
        setShowOtpPopup(true);
        setPhoneSubmitData(data);
      }
    } catch (error) {
      // console.log("error submitting phone", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="overflow-hidden relative gradient-bg h-[100dvh] text-kitsaWhite pt-5 flex flex-col justify-between">
      <div className="px-3 h-[40%]">
        <img
          src={kitsaFavicon}
          alt="Back"
          className="w-10 mb-10"
          onClick={() => navigate(-1)}
        />

        {showOtpPopup ? (
          <h3 className="text-3xl font-bold mb-8">
            Few more details left and you are done.
          </h3>
        ) : (
          <h3 className="text-3xl font-bold mb-8">
            Go ahead and set up <br /> your account
          </h3>
        )}
        <p className="text-base font-medium tracking-[0.26px]">
          Sign in/up to enjoy the best managing experience.
        </p>
      </div>

      <div className="bg-formisBlack w-full h-[60%] rounded-t-[20px] shadow-peachBox pt-7 px-[25px]">
        <div className="mb-7  font-bold text-sm w-full flex items-center">
          Enter your mobile number to proceed
        </div>

        <form onSubmit={handlePhoneSubmit}>
          <div className="bg-formisWhite w-full h-[51px] rounded-[10px] flex items-center gap-4 px-3 mb-7">
            <div className="flex items-center gap-2">
              <img src={call} alt="Mobile" />
              <span className="text-2xl">🇮🇳</span>
              <span className="relative top-[1px] text-formisBlack  font-medium text-sm">
                +91
              </span>
            </div>
            <input
              placeholder="Enter your mobile number"
              maxLength={10}
              required
              type="tel"
              onChange={(e) => {
                if (e.target.value.length <= 10) {
                  setMobile(e.target.value);
                }
              }}
              value={mobile}
              className="outline-none bg-transparent flex-1 text-sm relative top-[1px] text-formisBlack  font-medium placeholder:text-formisBlack"
            />
          </div>

          <div className="w-full flex items-center justify-center">
            <button
              type="submit"
              className="rounded-[8px] mb-[22px] w-[70%]"
            >
              <div className="bg-kitsaGreen text-kitsaWhite rounded-[8px]  font-bold text-sm h-10 flex items-center justify-center">
                {loading ? <div className="loader"></div> : "Login"}
              </div>
            </button>
          </div>
        </form>
      </div>

      <div
        className={`${
          showOtpPopup ? "translate-y-0" : "translate-y-full"
        } transitiion-transform duration-300 absolute bottom-0 h-[100dvh] w-full px-3 pb-2 flex items-end pointer-events-none`}
      >
        <OtpPopup
          otp={otp}
          setOtp={setOtp}
          phoneSubmitData={phoneSubmitData}
          mobile={mobile}
          showOtpPopup={showOtpPopup}
          setShowOtpPopup={setShowOtpPopup}
          handlePhoneSubmit={handlePhoneSubmit}
        />
      </div>
    </div>
  );
};

export default Login;

const OtpPopup = ({
  otp,
  setOtp,
  phoneSubmitData,
  mobile,
  showOtpPopup,
  setShowOtpPopup,
  handlePhoneSubmit,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getCartDetails } = useCartDetails();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [loading, setLoading] = useState(false);
  const cartItems = useSelector((state) => state.cartItems.items);
  const {
    address,
    address2,
    coordinates,
    city,
    state,
    pinCode,
    addressType,
    addressPerson,
    contactName,
    nearbyLandmark,
    contactNumber,
  } = useSelector((state) => state.googleMapLocation);

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value) && value !== "") return;

    setOtp((prevOtp) => [
      ...prevOtp.map((data, i) => (i === index ? value : data)),
    ]);

    if (value && index < otp.length - 1) {
      const nextInput =
        e.target.parentElement.nextElementSibling?.querySelector("input");
      if (nextInput) nextInput.focus();
    } else if (value === "" && index > 0) {
      const prevInput =
        e.target.parentElement.previousElementSibling?.querySelector("input");
      if (prevInput) prevInput.focus();
    }
  };

  const handleAddAddress = async () => {
    const access = localStorage.getItem("accessToken");

    try {
      // Function to fetch all pages of addresses
      const fetchAllAddresses = async () => {
        let allAddresses = [];
        let nextPageUrl = addressesApi;

        while (nextPageUrl) {
          const response = await fetch(nextPageUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access}`,
            },
          });

          if (!response.ok) {
            throw new Error("Failed to fetch addresses");
          }

          const data = await response.json();
          allAddresses = [...allAddresses, ...data.results];
          nextPageUrl = data.next; // The "next" URL for the next page
        }

        return allAddresses;
      };

      // Fetch all addresses
      const savedAddresses = await fetchAllAddresses();

      // Match the address
      const matchedAddress = savedAddresses.find((addr) => {
        const isLatLngMatch =
          parseFloat(addr.latitude).toFixed(6) ===
            parseFloat(coordinates.lat).toFixed(6) &&
          parseFloat(addr.longitude).toFixed(6) ===
            parseFloat(coordinates.lng).toFixed(6);

        const isAddressTypeMatch = addr.name === addressType;

        return isLatLngMatch || isAddressTypeMatch;
      });

      if (matchedAddress) {
        // console.log("Address matched");
        dispatch(
          setAddressDetails({
            address: matchedAddress.address_line_1,
            address2: matchedAddress.address_line_2 || "",
            city: matchedAddress.city,
            state: matchedAddress.state,
            pinCode: matchedAddress.postal_code,
            addressType: matchedAddress.name,
            addressId: matchedAddress.id,
            nearbyLandmark: matchedAddress.nearby_landmark,
            contactName: matchedAddress.contact_name,
            contactNumber: matchedAddress.contact_number,
          })
        );
        dispatch(
          setCoordinates({
            lat: parseFloat(matchedAddress.latitude),
            lng: parseFloat(matchedAddress.longitude),
          })
        );
      } else {
        // console.log("Address not found, saving address");
        const postResponse = await fetch(addressesApi, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access}`,
          },
          body: JSON.stringify({
            address_person: addressPerson,
            name: addressType,
            address_line_1: address,
            address_line_2: address2 || null,
            city: city,
            state: state,
            latitude: parseFloat(coordinates.lat.toFixed(6)),
            longitude: parseFloat(coordinates.lng.toFixed(6)),
            postal_code: pinCode,
            nearby_landmark: nearbyLandmark,
            contact_name: contactName,
            contact_number: contactNumber,
          }),
        });

        const data = await postResponse.json();

        if (postResponse.ok) {
          // console.log("Post response OK from login: ", postResponse);
        }
        dispatch(
          setAddressDetails({
            address,
            address2,
            city,
            state,
            pinCode,
            addressType,
            addressPerson,
            contactName,
            contactNumber,
            addressId: data.id,
          })
        );
      }
    } catch (error) {
      // console.log("Error from login address call: ", error);
    }
  };

  const handleAddToCartApiCall = async (quantity, product_id) => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id,
              quantity,
            },
          ],
        }),
      });
      if (response.ok) {
        // console.log(`product ${product_id} added to cart`);
      }
    } catch {}
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(verifyOtpApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile_number: mobile,
          otp: otp.join(""),
          ...(phoneSubmitData.new_user && {
            first_name: firstName,
            last_name: lastName,
          }),
        }),
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("accessToken", data.access_token);
        // localStorage.setItem("refreshToken", data.refresh_token);
        localStorage.setItem("isAuthenticated", true);
        await handleAddAddress();
        for (const item of cartItems) {
          await handleAddToCartApiCall(item.quantity, item.product_id);
        }
        await getCartDetails();
        // await dispatch(toggleIsAddressSliderOpen(true));
        // await dispatch(setAddressStep(2));
        // await navigate(-1);
        if (cartItems.length > 0) {
          navigate("/cart");
          // if (addressType === "") {
          //   dispatch(toggleIsAddressSliderOpen(true));
          //   dispatch(setAddressStep(2));
          // }
        } else navigate("/");
      } else {
      }
    } catch (error) {
      // console.log("error from verify otp api", error);
    } finally {
      setLoading(false);
    }
  };

  const [seconds, setSeconds] = useState(30);
  const [canResend, setCanResend] = useState(false);

  useEffect(() => {
    let timer;
    if (showOtpPopup) {
      if (seconds === 0) {
        setCanResend(true);
        return;
      }
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [showOtpPopup, seconds]);

  const handleResendOtp = () => {
    handlePhoneSubmit();
    setSeconds(30);
    setCanResend(false);
  };

  return (
    <div className="pointer-events-auto flex flex-col items-center w-full bg-formisBlack min-h-[76dvh] shadow-peachBox rounded-[20px] pt-10 pb-5">
      <div className="flex flex-col items-center mb-4">
        <img src={login} alt="Login" className="mb-4" />
        <h5 className=" font-bold mb-2">Verify your account</h5>
        <p className=" text-xs font-medium mb-1">
          Enter 6 digits verification code we have sent to
        </p>
        <div className=" flex items-center gap-3">
          <p className="text-xs underline font-bold">+91 {mobile}</p>
          <span
            onClick={() => setShowOtpPopup(false)}
            className="text-[10px] font-medium"
          >
            Change Number?
          </span>
        </div>
      </div>

      <form onSubmit={handleOtpSubmit} className="w-full px-4">
        <div
          className={`flex items-center gap-2 text-formisBlack font-bold ${
            phoneSubmitData?.new_user ? "mb-1" : "mb-8"
          }`}
        >
          {otp.map((data, index) => {
            return (
              <div key={index} className="flex-1">
                <input
                  type="tel"
                  value={data}
                  maxLength={1}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace" && !data && index > 0) {
                      const prevInput =
                        e.target.parentElement.previousElementSibling?.querySelector(
                          "input"
                        );
                      if (prevInput) prevInput.focus();
                    }
                  }}
                  className="h-[51px] w-full text-center outline-none rounded-[8px]"
                />
              </div>
            );
          })}
        </div>

        <div className="flex items-center gap-2 mb-8">
          <img src={retry} alt="Retry" />

          {canResend ? (
            <button
              onClick={handleResendOtp}
              className="relative text-kitsaGreen top-[2px] underline text-[12px] font-medium "
            >
              Resend OTP
            </button>
          ) : (
            <p className="relative top-[2px] text-[12px] font-medium text-kitsaGreen">
              Did not receive OTP? Try resend OTP in {seconds} seconds
            </p>
          )}
        </div>

        {phoneSubmitData?.new_user && (
          <div className="mb-8">
            <p className="text-sm font-medium mb-2">About You</p>
            <input
              onChange={(e) => setFirstName(e.target.value)}
              required
              className="w-full h-[41px] rounded-[10px] text-xs font-bold px-4 outline-none text-formisBlack mb-3"
              placeholder="Enter First Name"
            />
            <input
              onChange={(e) => setLastName(e.target.value)}
              required
              className="w-full h-[41px] rounded-[10px] text-xs font-bold px-4 outline-none text-formisBlack"
              placeholder="Enter Last Name"
            />
          </div>
        )}
        <button
          type="submit"
          disabled={otp.join("").length !== 6}
          className={`${
            otp.join("").length === 6
              ? "bg-kitsaGreen text-kitsaWhite"
              : "bg-[#666666]"
          } transition-colors duration-300 font-bold flex items-center justify-center h-[51px] w-full rounded-full`}
        >
          {loading ? <div className="loader"></div> : "Verify"}
        </button>
      </form>
    </div>
  );
};
