import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ProductCard from "../components/Product Page Components/ProductCard";
import ProductFeatures from "../components/Product Page Components/ProductFeatures";
import ProductFAQ from "../components/Product Page Components/ProductFAQ";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { productDataApi } from "../api";

import AddressSlider from "../components/AddressSlider";
import SurgeSlider from "../components/SurgeSlider";
import MobileFooter from "../components/MobileFooterMenu";
import Search from "../components/Search";
import Profile from "../components/Profile";
import ViewCartButton from "../components/Footer Menu Buttons/ViewCartButton";
import SearchBar from "../components/Main Page Components/SearchBar";

const Product = () => {
  const { slug } = useParams();
  const [productData, setProductData] = useState();

  const fetchProduct = async () => {
    try {
      const response = await fetch(productDataApi + slug, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        setProductData(data);
      }
    } catch {}
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  return (
    <div className="gradient-bg">
      <Topbar />
      <div className="pt-12">
        <Header />
      </div>

      <div className="text-formisBlack">
        <SearchBar bg="bg-transparent" showUpload={false} />
      </div>

      {productData && (
        <div className="text-kitsaWhite px-[1.85rem] md:px-10 md2:px-14 lg:px-9 xl:px-12 pb-20 pt-5">
          <ProductCard productData={productData} />
          <ProductFeatures productData={productData} />
          {productData.questions !== "NA" && (
            <ProductFAQ productData={productData} />
          )}
        </div>
      )}

      <Footer />
      <AddressSlider />
      <SurgeSlider />
      <div className="fixed z-[30] bottom-0 w-full left-0">
        <ViewCartButton />
        <MobileFooter showBack={true} />
      </div>
      <Search />
      <Profile />
    </div>
  );
};

export default Product;
