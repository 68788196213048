import { lazy, Suspense, useEffect, useRef, useState } from "react";

import MobileFooter from "../components/MobileFooterMenu";

import home from "../assets/icons/home-icon.svg";
import back from "../assets/icons/back-peach.svg";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/Order Track Components/Button";

import ProductCard from "../components/Order Track Components/ProductCard";
import {
  cancelOrderApi,
  currentUserOrderApi,
  featuredSectionsApi,
  orderStatusApi,
} from "../api";
import SponsorCard from "../components/Main Page Components/SponsorCard";
import { useDispatch, useSelector } from "react-redux";
import { setUpdates } from "../features/apiCartSlice";
import EndText from "../components/EndText";
import PinIcon from "../assets/icon components/PinIcon";
import Map from "../components/Order Track Components/Map";
import { setShowComingSoon } from "../features/slidersSlice";
import { toast } from "sonner";

const Search = lazy(() => import("../components/Search"));
const Profile = lazy(() => import("../components/Profile"));

const orderStatusMessages = {
  "ORDER PLACED":
    "Your order has been placed, we are waiting for pharmacist's approval.",
  "IN PROGRESS": "We are getting your order ready.",

  "INVOICE GENERATED": "Your invoice has been generated.",

  "ALL AVAILABLE": "All your order items are available and are getting packed.",
  "FULLY APPROVED":
    "Your order is fully approved and a rider is being assigned.",
  "RIDER ASSIGNED": "A rider has been assigned to your order.",

  "REACHED PICKUP LOCATION": "The rider has arrived at the pickup location.",
  "START TO PICKUP": "The rider is on the way to collect your order.",
  "ORDER PICKED UP": "Your order is on its way!.",

  "REACHED DELIVERY LOCATION": "The rider has reached your location.",
  DELIVERED: "Your order has been successfully delivered.",

  RETURNED: "Your order has been returned.",
  "OUT OF SERVICEABLE AREA": "We are unable to deliver to your location.",
  "ORDER MODIFIED":
    "A few of your essentials are not available for immediate delivery.",
  "PARTIALLY APPROVED": "Some items are pending for approval.",
  COMPLETED: "Your order is complete.",
  "CANCELLED BY USER": "Your order was cancelled.",
  "CANCELLED DUE TO UNAVAILABILITY":
    "The products are unavailable, and your order was cancelled.",
  "CANCELLED OTC": "Your order was cancelled.",
  "NO MEDICINE AVAILABLE":
    "The requested medicines are currently out of stock.",
};

const OrderTracking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [featured, setFeatured] = useState([]);
  const { updates } = useSelector((state) => state.apiCart);

  const featuredBlogs = async () => {
    try {
      const response = await fetch(featuredSectionsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setFeatured(data);
        // console.log(data);
      }
    } catch {}
  };

  const getOngoingOrder = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(currentUserOrderApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        if (
          data.status !== "DELIVERED" ||
          data.status !== "CANCELLED DUE TO UNAVAILABILITY" ||
          data.status !== "CANCELLED-OTC" ||
          data.status !== "CANCELLED BY USER" ||
          data.order_code !== null
        )
          // console.log("Ongoing Order", data);
          localStorage.setItem("orderCode", data.order_code);
      } else {
        localStorage.removeItem("orderCode");
        dispatch(setUpdates(null));
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const cancelOrder = async () => {
    const access = localStorage.getItem("accessToken");
    const orderCode = localStorage.getItem("orderCode");
    try {
      const response = await fetch(cancelOrderApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          order_code: orderCode,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (data.status) {
          navigate("/order-cancelled");
          dispatch(setUpdates(null));
        } else toast("Something Went Wrong!");
      } else {
        toast("Something Went Wrong!");
      }
    } catch (err) {}
  };

  useEffect(() => {
    featuredBlogs();
    getOngoingOrder();
  }, []);

  const getOrderUpdates = async () => {
    const access = localStorage.getItem("accessToken");
    const orderCode = localStorage.getItem("orderCode");
    try {
      const response = await fetch(orderStatusApi + orderCode, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        dispatch(setUpdates(data));
        console.log("Order Updates", data);
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  const intervalRef = useRef(null);
  useEffect(() => {
    if (localStorage.getItem("orderCode") !== "null") {
      getOrderUpdates();
      intervalRef.current = setInterval(() => {
        getOrderUpdates();
      }, 20000);
    }

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (updates?.status === "ORDER MODIFIED" && updates?.user_action_required)
      navigate("/alternates");
    if (
      updates?.status === "CANCELLED DUE TO UNAVAILABILITY" ||
      updates?.status === "CANCELLED-OTC" ||
      updates?.status === "NO MEDICINE AVAILABLE" ||
      updates?.status === "RETURNED" ||
      updates?.status === "CANCELLED BY USER" ||
      updates?.status === "OUT OF SERVICEABLE AREA"
    ) {
      localStorage.removeItem("orderCode");
      getOrderUpdates();
      return;
    }

    if (
      updates?.status === "CANCELLED DUE TO UNAVAILABILITY" ||
      updates?.status === "CANCELLED-OTC" ||
      updates?.status === "NO MEDICINE AVAILABLE" ||
      updates?.status === "CANCELLED BY USER"
    )
      navigate("/order-cancelled");

    if (updates?.status === "DELIVERED") {
      navigate("/order-delivered");
    }

    const timeoutId = setTimeout(() => {
      if (
        updates?.status === "RETURNED" ||
        updates?.status === "OUT OF SERVICEABLE AREA" ||
        updates?.status === "NO MEDICINE AVAILABLE"
      ) {
        dispatch(setUpdates(null));
        navigate("/");
      }
    }, 20000);

    return () => clearTimeout(timeoutId);
  }, [updates]);

  return (
    <div className="text-kitsaWhite gradient-bg overflow-y-scroll min-h-screen pb-80">
      {updates?.status === "DELVIERED" ? (
        <div></div>
      ) : (
        <div>
          <div className="mb-8 mt-5">
            <div className="px-3 flex items-center justify-between gap-3 mb-4">
              <p className="text-sm font-bold w-[calc(100%-111px)]">
                {orderStatusMessages[updates?.status] || updates?.status}
              </p>
              <a
                href="tel:+91-9911266699"
                className="w-[111px] flex justify-end"
              >
                <Button>Help</Button>
              </a>
            </div>

            <div className="px-3 flex items-center justify-between gap-2 mb-4">
              <div
                className={`${
                  updates?.status === "ORDER PLACED" ||
                  updates?.status === "IN PROGRESS" ||
                  updates?.status === "INVOICE GENERATED" ||
                  updates?.status === "ALL AVAILABLE" ||
                  updates?.status === "FULLY APPROVED" ||
                  updates?.status === "RIDER ASSIGNED" ||
                  updates?.status === "REACHED PICKUP LOCATION" ||
                  updates?.status === "ORDER PICKED UP" ||
                  updates?.status === "START TO PICKUP" ||
                  updates?.status === "REACHED DELIVERY LOCATION" ||
                  updates?.status === "DELIVERED"
                    ? "bg-kitsaWhite"
                    : "bg-[#666666]/50"
                } h-1 w-full rounded-full`}
              />
              <div
                className={`${
                  updates?.status === "INVOICE GENERATED" ||
                  updates?.status === "ALL AVAILABLE" ||
                  updates?.status === "FULLY APPROVED" ||
                  updates?.status === "RIDER ASSIGNED" ||
                  updates?.status === "REACHED PICKUP LOCATION" ||
                  updates?.status === "ORDER PICKED UP" ||
                  updates?.status === "START TO PICKUP" ||
                  updates?.status === "REACHED DELIVERY LOCATION" ||
                  updates?.status === "DELIVERED"
                    ? "bg-kitsaWhite"
                    : "bg-[#666666]/50"
                } h-1 w-full rounded-full`}
              />
              <div
                className={`${
                  updates?.status === "ALL AVAILABLE" ||
                  updates?.status === "FULLY APPROVED" ||
                  updates?.status === "RIDER ASSIGNED" ||
                  updates?.status === "REACHED PICKUP LOCATION" ||
                  updates?.status === "ORDER PICKED UP" ||
                  updates?.status === "START TO PICKUP" ||
                  updates?.status === "REACHED DELIVERY LOCATION" ||
                  updates?.status === "DELIVERED"
                    ? "bg-kitsaWhite"
                    : "bg-[#666666]/50"
                } h-1 w-full rounded-full`}
              />
              <div
                className={`${
                  updates?.status === "REACHED PICKUP LOCATION" ||
                  updates?.status === "ORDER PICKED UP" ||
                  updates?.status === "START TO PICKUP" ||
                  updates?.status === "REACHED DELIVERY LOCATION" ||
                  updates?.status === "DELIVERED"
                    ? "bg-kitsaWhite"
                    : "bg-[#666666]/50"
                } h-1 w-full rounded-full`}
              />
              <div
                className={`${
                  updates?.status === "REACHED DELIVERY LOCATION" ||
                  updates?.status === "DELIVERED"
                    ? "bg-kitsaWhite"
                    : "bg-[#666666]/50"
                } h-1 w-full rounded-full`}
              />
            </div>

            {updates?.rider_name && (
              <div className="px-3 flex items-center justify-between font-bold gap-3">
                {updates?.rider_otp ? (
                  <div className="bg-formisBlack border border-kitsaWhite rounded-[10px] flex items-center gap-4 pl-1 pr-3 w-[75%] h-[38px]">
                    <div className=" font-bold text-xs bg-kitsaGreen h-[28px] flex items-center justify-center w-[70px] rounded-[10px] text-kitsaWhite">
                      {updates?.rider_otp}
                    </div>
                    <p className="font-bold text-[10px] leading-[.85rem]">
                      Your order has arrived. <br /> Please share OTP to the
                      rider
                    </p>
                  </div>
                ) : (
                  <div className="bg-formisBlack border border-kitsaWhite rounded-[10px] flex items-center gap-4 pl-1 pr-3 w-[75%] h-[38px]">
                    <p className="text-xs font-bold">
                      Rider name : {updates?.rider_name}
                    </p>
                  </div>
                )}

                {updates?.rider_mobile && (
                  <div className="bg-formisBlack border border-kitsaWhite rounded-[10px] flex items-center justify-center gap-4 flex-1 h-[38px]">
                    <a
                      href={`tel:+91-${updates?.rider_mobile}`}
                      className="text-[10px]"
                    >
                      Call Rider
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>

          <h4 className="text-sm font-bold mb-3 px-3">
            Your order information
          </h4>

          <div className="px-3">
            {/* PAYMENT */}
            {!updates?.payment_completed && updates?.payment_link && (
              <div className="bg-formisBlack font-semibold text-xs h-[38px] border border-kitsaWhite flex items-center justify-between w-full rounded-[10px] px-[6px] mb-3">
                <p className="text-xs  font-semibold text-kitsaWhite">
                  Pay now or at the time of delivery
                </p>
                <a href={updates?.payment_link} target="_blank">
                  <Button>Pay Now</Button>
                </a>
              </div>
            )}

            {/* ADDRESS */}
            <div className="bg-formisBlack text-kitsaWhite rounded-[10px] mb-3">
              <Map
                latitude={parseFloat(
                  updates?.shipping_address.latitude
                ).toFixed(6)}
                longitude={parseFloat(
                  updates?.shipping_address.longitude
                ).toFixed(6)}
                height="h-[40dvh]"
              />
              <div className="bg-[#666666]/50 text-kitsaWhite rounded-[8px] p-2 mt-3">
                <p className="text-xs font-[700] mb-5">
                  Delivering your order to "{updates?.shipping_address.name}"
                </p>
                <div className="flex items-center justify-between rounded-[10px] mb-2">
                  <div className="flex items-center gap-7">
                    {/* <PiMapPinLineFill className="text-4xl shrink-0" /> */}
                    <PinIcon color="#47b24f" width="35" />
                    <div className="text-xs">
                      <p className="font-[700] text-sm capitalize">
                        {updates?.shipping_address.address_line_1}
                      </p>
                      <p className="font-[500] capitalize">
                        {updates?.shipping_address.address_line_2},{" "}
                        {updates?.shipping_address.city}
                      </p>
                    </div>
                  </div>
                  <a href="tel:+91-9911266699">
                    <Button color="kitsaGreen" textColor="kitsaWhite">
                      Get Help
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <div className="bg-formisBlack font-semibold text-xs h-[38px] border border-kitsaWhite flex items-center justify-between w-full rounded-[10px] px-[6px]">
                <p className="text-xs  font-semibold text-kitsaWhite">
                  Explore nearby vouchers
                </p>

                <Button onClick={() => dispatch(setShowComingSoon(true))}>
                  View
                </Button>
              </div>
            </div>
            <div className="mb-3">
              <div className="bg-formisBlack font-semibold text-xs h-[38px] border border-kitsaWhite flex items-center justify-between w-full rounded-[10px] px-[6px]">
                <p className="text-xs  font-semibold text-kitsaWhite">
                  Download your earned voucher
                </p>

                <Button onClick={() => dispatch(setShowComingSoon(true))}>
                  Download
                </Button>
              </div>
            </div>

            {/* ORDER ITEMS */}
            <div className="bg-formisBlack border border-kitsaWhite rounded-[10px] px-[6px] py-2 mb-3">
              <div className="flex items-center justify-between">
                <div className=" font-bold">
                  <p className="text-xs">
                    Shipment of{" "}
                    {
                      updates?.order_items.filter(
                        (item) => item.status === "AVAILABLE"
                      ).length
                    }{" "}
                    essentials
                  </p>
                </div>
                {/* <div className=" font-bold text-[11px] bg-kitsaGreen h-[28px] flex items-center justify-center w-[111px] rounded-[8px] text-kitsaWhite">
                  ₹{updates?.total}
                </div> */}
              </div>

              <div className="flex flex-col gap-4 pt-4">
                {updates?.order_items
                  .filter((item) => item.status === "AVAILABLE")
                  .map((item) => (
                    <ProductCard key={item.product.product_id} product={item} />
                  ))}
              </div>
            </div>

            {/* INVOICE */}
            <div className="mb-3">
              <div>
                <div className="bg-formisBlack font-semibold text-xs h-[38px] border border-kitsaWhite flex items-center justify-between w-full rounded-[10px] px-[6px]">
                  <p className="text-xs  font-semibold text-kitsaWhite">
                    Grand Total
                  </p>
                  <a href={updates?.invoice_url} target="_blank">
                    <Button>₹{updates?.total}</Button>
                  </a>
                </div>
              </div>
            </div>
            {updates?.invoice_url && (
              <div className="mb-3">
                <div>
                  <div className="bg-formisBlack font-semibold text-xs h-[38px] border border-kitsaWhite flex items-center justify-between w-full rounded-[10px] px-[6px]">
                    <p className="text-xs  font-semibold text-kitsaWhite">
                      Get your invoice copy
                    </p>
                    <a href={updates?.invoice_url} target="_blank">
                      <Button>Download</Button>
                    </a>
                  </div>
                </div>
              </div>
            )}

            {/* CONTACT */}
            <div className="bg-formisBlack font-semibold text-xs h-[38px] border border-kitsaWhite flex items-center justify-between w-full rounded-[10px] px-[6px] mb-3">
              <p className="text-xs  font-semibold text-kitsaWhite">
                Need help with your order?
              </p>
              <a href="tel:+91-9911266699">
                <Button>Get Help</Button>
              </a>
            </div>

            {/* CANCEL ORDER */}
            <div className="bg-formisBlack font-semibold text-xs h-[38px] border border-kitsaWhite flex items-center justify-between w-full rounded-[10px] px-[6px] mb-3">
              <p className="text-xs  font-semibold text-kitsaWhite">
                Changed your mind?
              </p>

              <Button onClick={cancelOrder}>Cancel Order</Button>
            </div>

            {/* <div className="bg-formisBlack font-semibold text-xs h-[38px] border border-kitsaWhite flex items-center justify-between w-full rounded-[10px] px-[6px] mb-3">
              <p className="text-xs  font-semibold text-kitsaWhite">
                Number of orders delivered till date.
              </p>

              <div className=" font-bold text-xs bg-kitsaGreen h-[28px] flex items-center justify-center w-[70px] rounded-[10px] text-kitsaWhite">
                25000
              </div>
            </div> */}
          </div>

          <div className="flex flex-col gap-12 mt-10">
            <div className="-mb-5">
              <SponsorCard
                featured={featured}
                transparent={true}
                name="Featured"
              />
            </div>
            <SponsorCard
              featured={featured}
              transparent={true}
              name="Featured"
            />
            <SponsorCard
              featured={featured}
              transparent={true}
              name="Featured"
            />
          </div>
        </div>
      )}

      <EndText />

      <div className="fixed z-[30] bottom-0 w-full left-0">
        {updates?.status === "DELIVERED" ||
        updates?.status === "CANCELLED DUE TO UNAVAILABILITY" ||
        updates?.status === "CANCELLED-OTC" ||
        updates?.status === "CANCELLED BY USER" ? (
          <PlaceOrderButton />
        ) : (
          ""
        )}
        <MobileFooter />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <Search />
        <Profile />
      </Suspense>
    </div>
  );
};

export default OrderTracking;

const PlaceOrderButton = () => {
  return (
    <div className="relative flex items-center justify-center gap-3 mb-3">
      <Link
        to="/"
        className={`relative flex items-center gap-2 justify-center text-kitsaWhite`}
      >
        <div className="bg-formisBlack w-fit p-2 rounded-full flex items-center gap-3 shadow-cartButtons">
          <div className="size-7 border border-kitsaWhite rounded-full flex items-center justify-center  text-[11px] font-bold">
            0
          </div>
          <div className="text-xs font-bold">Place a new order</div>
          <img src={back} alt="next" className="rotate-180 w-4" />
        </div>
      </Link>

      <Link
        to="/"
        className="bg-formisBlack h-full border border-kitsaWhite p-[9px] rounded-full"
      >
        <img src={home} alt="Home" />
      </Link>
    </div>
  );
};
