import { BrowserRouter, Route, Routes } from "react-router-dom";
import CheckoutCart from "./pages/CheckoutCart";
import Main from "./pages/Main";
import Product from "./pages/Product";
import AllProducts from "./pages/AllProducts";
import Login from "./pages/Login";
import ScrollToTop from "./components/ScrollToTop";
import CategoryPage from "./pages/CategoryPage";
import { useEffect, useState } from "react";
import WeatherDetails from "./pages/WeatherDetails";
import OrderTracking from "./pages/OrderTracking";
import Alternates from "./pages/Alternates";

import Lottie from "lottie-react";
import rotatePhone from "./assets/phone-rotate.json";
import { Toaster } from "sonner";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Blog from "./pages/Blog/Blog";
import BlogPost from "./pages/Blog/BlogPost";
import { useDispatch, useSelector } from "react-redux";
import { setSearchComponent } from "./features/searchSlice";
import PrivacyPolicy from "./pages/Policies/PrivacyPolicy";
import TermsPolicy from "./pages/Policies/TermsPolicy";
import ShippingRefundsPolicy from "./pages/Policies/ShippingRefundsPolicy";
import { setProfileComponent } from "./features/profileSlice";
import { toggleIsAddressSliderOpen } from "./features/mapSlice";
import WaitingScreen from "./pages/WaitingScreen";
import OrderDelivered from "./pages/OrderDelivered";
import OrderCancelled from "./pages/OrderCancelled";
import TestPage from "./pages/TestPage";

const App = () => {
  const dispatch = useDispatch();
  const { isSearchOpen } = useSelector((state) => state.search);
  const [showPortraitNotif, setShowPortraitNotif] = useState(false);
  const isProfileOpen = useSelector((state) => state.profile.isProfileOpen);
  const { isAddressSliderOpen } = useSelector(
    (state) => state.googleMapLocation
  );

  // useEffect(() => {
  //   window.location.replace('https://kitsa.in');
  // }, [])

  // CHECK IF USER IS IN LANDSCAPE MODE
  useEffect(() => {    
    const handleOrientationChange = () => {
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);

      if (
        isMobile &&
        (window.screen.orientation.type === "landscape-primary" ||
          window.screen.orientation.type === "landscape-secondary")
      ) {
        setShowPortraitNotif(true);
      }
    };
    window.screen.orientation.addEventListener(
      "change",
      handleOrientationChange
    );

    handleOrientationChange();

    return () => {
      window.screen.orientation.removeEventListener(
        "change",
        handleOrientationChange
      );
    };
  }, []);

  // CLOSE SEARCH SLIDER ON BACK BUTTON
  useEffect(() => {
    if (isSearchOpen) {
      window.history.pushState({ modal: true }, "");
    }
    const handlePopState = (event) => {
      if (event.state?.modal) {
        dispatch(setSearchComponent(true));
      } else {
        dispatch(setSearchComponent(false));
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isSearchOpen]);

  // CLOSE PROFILE SLIDER ON BACK BUTTON
  useEffect(() => {
    if (isProfileOpen) {
      window.history.pushState({ profileModal: true }, "");
    }
    const handlePopState = (event) => {
      if (event.state?.profileModal) {
        dispatch(setProfileComponent(true));
      } else {
        dispatch(setProfileComponent(false));
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isProfileOpen]);

  // CLOSE ADDRESS SLIDER ON BACK BUTTON
  useEffect(() => {
    if (isAddressSliderOpen) {
      window.history.pushState({ addressModal: true }, "");
    } else {
      window.history.pushState({ addressModal: false }, "");
    }

    const handlePopState = (event) => {
      if (event.state?.addressModal) {
        dispatch(toggleIsAddressSliderOpen(true));
      } else {
        dispatch(toggleIsAddressSliderOpen(false));
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isAddressSliderOpen]);

  return (
    <BrowserRouter>
      <Toaster
        position="top-center"
        toastOptions={{
          className: "bg-formisBlack shadow-md shadow-kitsaWhite text-kitsaWhite border border-kitsaWhite",
          duration: 1500,
        }}
      />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/cart" element={<CheckoutCart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/categories/:categoryName" element={<CategoryPage />} />
        <Route path="/product/:slug" element={<Product />} />
        <Route path="/weather" element={<WeatherDetails />} />
        <Route path="/all-products" element={<AllProducts />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogId" element={<BlogPost />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsPolicy />} />
        <Route
          path="/shipping-and-refunds-policy"
          element={<ShippingRefundsPolicy />}
        />

        <Route path="/track-order" element={<OrderTracking />} />
        <Route path="/alternates" element={<Alternates />} />
        <Route path="/pending-approval" element={<WaitingScreen />} />
        <Route path="/order-delivered" element={<OrderDelivered />} />
        <Route path="/order-cancelled" element={<OrderCancelled />} />
        {/* <Route path="/test-page" element={<TestPage />} /> */}
      </Routes>

      {showPortraitNotif && (
        <div className="absolute z-[31] left-0 top-0 h-[100dvh] w-screen bg-formisBlack/70 flex items-center justify-center">
          <div className="bg-[#203040] border border-[#666666] px-3 py-4 rounded-[10px] flex flex-col justify-center items-center shadow-peachBox">
            <div className="max-w-16 mb-3">
              <Lottie animationData={rotatePhone} />
            </div>
            <div className="text-sm font-bold text-kitsaWhite text-center mb-3">
              Please switch back to portrait mode <br /> for the best
              experience.
            </div>
            <button
              onClick={() => setShowPortraitNotif(false)}
              className="font-[MestizaSans] font-bold text-xs bg-kitsaWhite h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-kitsaWhite"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </BrowserRouter>
  );
};

export default App;