import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Lottie from "lottie-react";
import cancelled from "../assets/cancelled.json";
import kitsaLogo from "../assets/kitsa-logo-green.png";
import FeedbackAccordian from "../components/Order Track Components/FeedbackAccordian";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import home from "../assets/icons/home-icon.svg";
import { orderStatusApi, profileApi, requestProductApi } from "../api";
import { useDispatch } from "react-redux";
import { toast } from "sonner";
import { setUpdates } from "../features/apiCartSlice";

// const updatesSample = {
//   id: 97,
//   user: "4802faa1-9719-4df3-9936-0afe11616879",
//   total: "818.64",
//   discount: "63.36",
//   gst_amount: "134.54",
//   cgst_amount: "67.28",
//   sgst_amount: "67.28",
//   shipping_address: {
//     id: "f03511a9-cf5d-4f6d-b5f3-6b50f3b02d6b",
//     user: "4802faa1-9719-4df3-9936-0afe11616879",
//     address_person: "MYSELF",
//     name: "Hotel",
//     address_line_1: "guru nanak",
//     address_line_2: "Sector 24",
//     city: "Gurugram",
//     state: "Haryana",
//     latitude: "28.494931",
//     longitude: "77.100140",
//     postal_code: "122022",
//     is_default: false,
//     nearby_landmark: "e2fwqf",
//     contact_name: "bhanu",
//     contact_number: "7058000029",
//   },
//   total_price: "882.00",
//   status: "ORDER MODIFIED",
//   created_at: "2025-02-06T14:24:29.373235+05:30",
//   updated_at: "2025-02-06T14:25:15.141381+05:30",
//   fast_pass_order_code: "ZETA-0971-7861",
//   fast_pass_order_id: 6864,
//   applied_coupon: null,
//   coupon_discount: "0.00",
//   order_items: [
//     {
//       id: 169,
//       user: "4802faa1-9719-4df3-9936-0afe11616879",
//       order: 97,
//       product: {
//         name: "Trisa Perfect White Toothbrush Medium",
//         manufacturer: "Rama Vision Limited",
//         salt_composition: "NA",
//         description:
//           "Trisa Perfect White Toothbrush can improve your dental health by removing plaque in more significant quantities and allowing toothpaste and brush bristles to reach more of the tooth.Uses:\n\nIt removes plaque and bacteria effectively\n\nProduct Specifications and Features:\n\nThe soft bristles gently stimulate the gums whilst cleaning the surface of the teeth at the same time\n\n\nShorter Cceaning bristles reliably remove plaque from the tooth surfaces\n\n\nErgonomic handle with a non-slip surface\n\n\nSoft microstructures on the back help remove bacteria from the tongue\n\nDirections for Use:Brush your teeth twice daily.Safety Information:\n\nRead the label carefully before use",
//         primary_image_url: "https://medicinedata.in/o/DR055225_1.jpg",
//         category: "Skin, hair & oral care",
//         packaging: "packet of 1 Toothbrush",
//         package: "Packet",
//         package_quantity: "1",
//         product_form: "Toothbrush",
//         mrp: "329.00",
//         prescription_required: "NA",
//         slug_field: "trisa-perfect-white-toothbrush-medium",
//         sku_id: "DR546319",
//         fast_pass_id: 937025,
//         product_id: "PID2021389364",
//       },
//       quantity: 1,
//       mrp: "329.00",
//       gst_percentage: "18.00",
//       cgst_percentage: "9.00",
//       sgst_percentage: "9.00",
//       gst_amount: "50.19",
//       cgst_amount: "25.10",
//       sgst_amount: "25.10",
//       discount_amount: "26.32",
//       discount_percentage: "8.00",
//       selling_price_at_time: "302.68",
//       status: "ALTERNATE SUGGESTED",
//       alternate_product: {
//         id: 15,
//         product: {
//           name: "Boost Powder Refill Pack Malt (1kg)",
//           manufacturer: "Hindustan Unilever Ltd",
//           salt_composition: "",
//           description: "NA",
//           primary_image_url:
//             "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png",
//           category: "NA",
//           packaging: "Box of 1kg Powder",
//           package: "Box of 1kg Powder",
//           package_quantity: "Box of 1kg Powder",
//           product_form: "NA",
//           mrp: "450.00",
//           prescription_required: "NA",
//           slug_field: "boost-powder-refill-pack-malt-1kg",
//           sku_id: "SKU_2020100111011",
//           fast_pass_id: 27718,
//           product_id: "PID2021025896",
//         },
//         quantity: 1,
//         mrp: "450.00",
//         gst_percentage: "18.00",
//         cgst_percentage: "9.00",
//         sgst_percentage: "9.00",
//         gst_amount: "68.64",
//         cgst_amount: "34.32",
//         sgst_amount: "34.32",
//         discount_amount: "36.00",
//         discount_percentage: "8.00",
//         selling_price_at_time: "414.00",
//       },
//       total_price: "302.68",
//       user_accepted_alternate: "NO ACTION TAKEN",
//     },
//     {
//       id: 170,
//       user: "4802faa1-9719-4df3-9936-0afe11616879",
//       order: 97,
//       product: {
//         name: "Haslab Bico 41 Biochemic Compound Tablet",
//         manufacturer: "HASLAB",
//         salt_composition: "NA",
//         description:
//           "Haslab Bico 41 Biochemic Compound Tablet helps to restore the balance of minerals in the body and improve overall health. It helps to provide relief from ulcers or blisters inside the mouth, and on the tongue in feeding children or adults.",
//         primary_image_url: "https://medicinedata.in/o/DR030771_1.jpg",
//         category: "Skin, hair & oral care",
//         packaging: "bottle of 20 gm Tablet",
//         package: "Bottle",
//         package_quantity: "20 gm",
//         product_form: "Tablet",
//         mrp: "75.00",
//         prescription_required: "NA",
//         slug_field: "haslab-bico-41-biochemic-compound-tablet",
//         sku_id: "DR521865",
//         fast_pass_id: 943079,
//         product_id: "PID2021395418",
//       },
//       quantity: 1,
//       mrp: "75.00",
//       gst_percentage: "18.00",
//       cgst_percentage: "9.00",
//       sgst_percentage: "9.00",
//       gst_amount: "11.44",
//       cgst_amount: "5.72",
//       sgst_amount: "5.72",
//       discount_amount: "2.25",
//       discount_percentage: "3.00",
//       selling_price_at_time: "72.75",
//       status: "ALTERNATE SUGGESTED",
//       alternate_product: {
//         id: 16,
//         product: {
//           name: "Horlicks Junior Horlicks Health and Nutrition Drink Vanilla (500gm)",
//           manufacturer: "Hindustan Unilever Ltd",
//           salt_composition: "",
//           description: "NA",
//           primary_image_url:
//             "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png",
//           category: "NA",
//           packaging: "Jar of 500gm Powder",
//           package: "Jar of 500gm Powder",
//           package_quantity: "Jar of 500gm Powder",
//           product_form: "NA",
//           mrp: "288.00",
//           prescription_required: "NA",
//           slug_field:
//             "horlicks-junior-horlicks-health-and-nutrition-drink-vanilla-500gm",
//           sku_id: "SKU_2020100111011",
//           fast_pass_id: 68870,
//           product_id: "PID2021063560",
//         },
//         quantity: 1,
//         mrp: "288.00",
//         gst_percentage: "18.00",
//         cgst_percentage: "9.00",
//         sgst_percentage: "9.00",
//         gst_amount: "43.93",
//         cgst_amount: "21.97",
//         sgst_amount: "21.97",
//         discount_amount: "23.04",
//         discount_percentage: "8.00",
//         selling_price_at_time: "264.96",
//       },
//       total_price: "72.75",
//       user_accepted_alternate: "NO ACTION TAKEN",
//     },
//     {
//       id: 171,
//       user: "4802faa1-9719-4df3-9936-0afe11616879",
//       order: 97,
//       product: {
//         name: "Bentodent Clean Bamboo Tongue Cleaner",
//         manufacturer: "Indian Dental Organization Private Limited",
//         salt_composition: "NA",
//         description:
//           "Bentodent Clean Bamboo Tongue Cleaner is made with pure bamboo wood and is biodegradable. It can help clean the tongue and is broad to help cover the maximum tongue area.",
//         primary_image_url: "https://medicinedata.in/o/DR055641_1.jpg",
//         category: "Skin, hair & oral care",
//         packaging: "packet of 1 Unit",
//         package: "Packet",
//         package_quantity: "1",
//         product_form: "Unit",
//         mrp: "79.00",
//         prescription_required: "NA",
//         slug_field: "bentodent-clean-bamboo-tongue-cleaner",
//         sku_id: "DR546735",
//         fast_pass_id: 946805,
//         product_id: "PID2021399145",
//       },
//       quantity: 1,
//       mrp: "79.00",
//       gst_percentage: "18.00",
//       cgst_percentage: "9.00",
//       sgst_percentage: "9.00",
//       gst_amount: "12.05",
//       cgst_amount: "6.03",
//       sgst_amount: "6.03",
//       discount_amount: "2.37",
//       discount_percentage: "3.00",
//       selling_price_at_time: "76.63",
//       status: "UNAVAILABLE",
//       alternate_product: null,
//       total_price: "76.63",
//       user_accepted_alternate: "NO ACTION TAKEN",
//     },
//     {
//       id: 172,
//       user: "4802faa1-9719-4df3-9936-0afe11616879",
//       order: 97,
//       product: {
//         name: "Basic Ayurveda Mouth Gel for LA",
//         manufacturer: "Basic Ayurveda",
//         salt_composition: "NA",
//         description:
//           "Basic Ayurveda Mouth Gel for LA is an ayurvedic formulation that helps with mouth ulcers. It provides instant relief from pain and irritation.",
//         primary_image_url: "https://medicinedata.in/o/DR014196_1.jpg",
//         category: "Skin, hair & oral care",
//         packaging: "bottle of 10 gm Gel",
//         package: "Bottle",
//         package_quantity: "10 gm",
//         product_form: "Gel",
//         mrp: "65.00",
//         prescription_required: "NA",
//         slug_field: "basic-ayurveda-mouth-gel-for-la",
//         sku_id: "DR505290",
//         fast_pass_id: 947582,
//         product_id: "PID2021399922",
//       },
//       quantity: 1,
//       mrp: "65.00",
//       gst_percentage: "18.00",
//       cgst_percentage: "9.00",
//       sgst_percentage: "9.00",
//       gst_amount: "9.92",
//       cgst_amount: "4.96",
//       sgst_amount: "4.96",
//       discount_amount: "1.95",
//       discount_percentage: "3.00",
//       selling_price_at_time: "63.05",
//       status: "UNAVAILABLE",
//       alternate_product: null,
//       total_price: "63.05",
//       user_accepted_alternate: "NO ACTION TAKEN",
//     },
//   ],
//   user_action_required: true,
//   user_action_taken: false,
//   user_action: "NO ACTION TAKEN",
//   invoice_url: null,
//   rider_name: null,
//   rider_mobile: null,
//   payment_completed: false,
//   payment_method: null,
//   rider_otp: null,
//   payment_link: null,
// };

const OrderCancelled = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [profileData, setProfileData] = useState();
  const [condition, setCondition] = useState();
  const [additionalComments, setAdditionalComments] = useState();
  const [updates, setOrderUpdates] = useState();

  const [showSubmitButton, setShowSubmitButton] = useState();
  const [showSubmitted, setShowSubmitted] = useState(false);

  const animationRef = useRef(null);
  const contentRef = useRef(null);
  const logoRef = useRef(null);

  const fetchProfile = async () => {
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch(profileApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setProfileData(data);
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      }
    } catch (error) {
      // console.error("Error fetching profile:", error);
    }
  };

  const sendRequest = async () => {
    const items = updates?.order_items
      .filter((item) => item.status === "UNAVAILABLE")
      .map((item) => item.product.name)
      .join(", ");

    try {
      const response = await fetch(requestProductApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contact_number: profileData.mobile_number,
          contact_name: profileData.first_name + " " + profileData.last_name,
          requested_item: items,
        }),
      });
      // const data = await response.json();
      if (response.ok) {
        toast.success("Your Request Was Sent Successfully!");
        navigate("/")
      } else {
        toast.error("Something Went Wrong!");
      }
    } catch (err) {
      console.log("Error sending request", err);
    }
  };

  const getOrderUpdates = async () => {
    const access = localStorage.getItem("accessToken");
    const orderCode = localStorage.getItem("orderCode");
    try {
      const response = await fetch(orderStatusApi + orderCode, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setOrderUpdates(data);
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getOrderUpdates();
    fetchProfile();

    const tl = gsap.timeline();

    tl.fromTo(
      animationRef.current,
      { scale: 1.25, opacity: 1 },
      { scale: 1, duration: 1, ease: "power2.out", delay: 0.2 }
    );

    setTimeout(() => {
      tl.to(animationRef.current, {
        width: "40dvw",
        duration: 1,
        ease: "power2.out",
      });

      tl.to(contentRef.current, { opacity: 1, duration: 1 }, "-=0.5");
      tl.to(logoRef.current, { opacity: 1, duration: 1 }, "-=0.5");
    }, 2500);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSubmitted(true);
  };

  useEffect(() => {
    if (condition) setShowSubmitButton(true);
  }, [condition]);

  useEffect(() => {
    let timeoutId;
    if (showSubmitted) {
      timeoutId = setTimeout(() => {
        navigate("/");
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [showSubmitted]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.removeItem("orderCode");
      dispatch(setUpdates(null));
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="text-kitsaWhite px-3 flex flex-col justify-center items-center min-h-[100dvh] pt-3 overflow-hidden">
      <div>
        {/* Lottie Animation */}
        <div className="w-[30dvw] mx-auto -mt-20" ref={animationRef}>
          <Lottie animationData={cancelled} loop={false} />
        </div>

        <div ref={contentRef} className="opacity-0 mt-5">
          <Link
            to="/"
            className="absolute top-0 right-0 flex items-center gap-2 py-[2px] bg-kitsaGreen px-2 text-sm font-medium"
          >
            <img src={home} alt="home" className="" />
            <p>Return to home</p>
          </Link>

          <div className="px-10">
            {updates?.status === "CANCELLED BY USER" ? (
              <p className="text-center text-sm font-bold mb-5">
                Your order has been cancelled.
              </p>
            ) : (
              <p className="text-center text-sm font-bold mb-5">
                Unfortunately your requested product is not available in stock
                right now. <br /> If you need it kindly place a request for the
                same, we will get back to you!
              </p>
            )}
          </div>

          {updates?.status === "CANCELLED BY USER" ? (
            <FeedbackAccordian
              title="Please let us know why to help us
            improve!"
              open={true}
            >
              <form onSubmit={handleSubmit}>
                {/* PRODUCT CONDITION */}
                <div className="mb-5">
                  <div className="flex flex-col gap-4 w-fit mx-auto">
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="reason"
                        value="Changed Mind"
                        checked={condition === "Changed Mind"}
                        onChange={(e) => setCondition(e.target.value)}
                        className="hidden peer"
                      />
                      <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                      <span className="text-xs font-bold">Changed Mind</span>
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="reason"
                        value="Found a Better Price"
                        checked={condition === "Found a Better Price"}
                        onChange={(e) => setCondition(e.target.value)}
                        className="hidden peer"
                      />
                      <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                      <span className="text-xs font-bold">
                        Found a Better Price
                      </span>
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="reason"
                        value="Shipping Time Too Long"
                        checked={condition === "Shipping Time Too Long"}
                        onChange={(e) => setCondition(e.target.value)}
                        className="hidden peer"
                      />
                      <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                      <span className="text-xs font-bold">
                        Shipping Time Too Long
                      </span>
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="reason"
                        value="Product was Unavailable"
                        checked={condition === "Product was Unavailable"}
                        onChange={(e) => setCondition(e.target.value)}
                        className="hidden peer"
                      />
                      <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                      <span className="text-xs font-bold">
                        Product was Unavailable
                      </span>
                    </label>

                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name="reason"
                        value="Other"
                        checked={condition === "Other"}
                        onChange={(e) => setCondition(e.target.value)}
                        className="hidden peer"
                      />
                      <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                      <span className="text-xs font-bold">Other</span>
                    </label>
                  </div>
                </div>

                {condition === "Other" && (
                  <div>
                    <p className="text-sm font-medium mb-2">
                      Please share the details:
                    </p>
                    <textarea
                      required
                      className="w-[80%] h-18 rounded-[8px] outline-none text-formisBlack p-2 text-xs font-medium"
                      onChange={(e) => setAdditionalComments(e.target.value)}
                    />
                  </div>
                )}

                <button
                  onTouchStart={(e) =>
                    e.currentTarget.classList.add("scale-95", "opacity-70")
                  }
                  onTouchEnd={(e) =>
                    e.currentTarget.classList.remove("scale-95", "opacity-70")
                  }
                  type="submit"
                  disabled={!showSubmitButton}
                  className={`${
                    showSubmitButton ? "opacity-100" : "opacity-0"
                  } mt-3 bg-kitsaGreen w-[40%] font-medium py-1 rounded-[8px] transition-opacity duration-300`}
                >
                  {showSubmitted ? "Submitted" : "Submit"}
                </button>
              </form>
            </FeedbackAccordian>
          ) : (
            <div className="flex items-center justify-center">
              <button
                onClick={sendRequest}
                onTouchStart={(e) =>
                  e.currentTarget.classList.add("scale-95", "opacity-70")
                }
                onTouchEnd={(e) =>
                  e.currentTarget.classList.remove("scale-95", "opacity-70")
                }
                className="mt-3 bg-kitsaGreen font-medium py-1 px-2 rounded-[8px] transition-opacity duration-300"
              >
                Request Unavailable Products
              </button>
            </div>
          )}
        </div>
      </div>

      <div ref={logoRef} className="opacity-0 fixed bottom-0 w-full ">
        <img src={kitsaLogo} alt="Kitsa" className="w-[80dvw] mx-auto" />
      </div>
    </div>
  );
};

export default OrderCancelled;
