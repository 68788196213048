import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import MainPage from "./Search Components/MainPage";
import SearchPage from "./Search Components/SearchPage";
import ProductPage from "./Search Components/ProductPage";
import CategoryProductsPage from "./Search Components/CategoryProductsPage";

import { toggleSearchComponent } from "../features/searchSlice";

import MobileFooter from "./MobileFooterMenu";
import ViewCartButton from "./Footer Menu Buttons/ViewCartButton";

const Search = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [whichPage, setWhichPage] = useState("main");
  const { isRequestOpen } = useSelector((state) => state.search);

  const isSearchOpen = useSelector((state) => state.search.isSearchOpen);
  const toggleSearch = () => {
    dispatch(toggleSearchComponent());
  };

  // CHANGE COMPONENT BASED ON QUERY PARAMETER
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const pageParam = queryParams.get("search");
    if (pageParam) {
      setWhichPage(pageParam);
    } else {
      setWhichPage("main");
    }
  }, [location]);

  const handlePageChange = (pageName) => {
    setWhichPage(pageName);
    navigate(`?search=${pageName}`);
  };

  //DISABLE SCROLL WHEN SEARCH SLIDER IS OPEN
  useEffect(() => {
    const main = document.getElementById("main-page");
    if (isSearchOpen) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
      if (location.pathname === "") main.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
      if (location.pathname === "") main.style.overflowY = "scroll";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
      if (location.pathname === "") main.style.overflowY = "scroll";
    };
  }, [isSearchOpen]);

  return (
    <div className="text-formisBlack">
      <div
        className={`transition-opacity duration-500 ${
          isSearchOpen ? "bg-formisBlack/30 z-20" : "bg-transparent -z-50"
        } fixed inset-0 cross-cursor`}
        onClick={toggleSearch}
      />
      <div
        id="search-modal-main"
        className={`fixed md2:top-0 md2:right-0 gradient-bg z-[31] md2:rounded-tl-[20px] md2:rounded-l-[20px] w-screen h-[calc(100dvh-2rem)] md2:w-[70vw] lg:w-[50vw] xl:w-[45vw] 2xl:w-[40vw] 3xl:w-[35vw] md2:pt-10 md2:h-screen
               transition-transform duration-500 flex flex-col text-kitsaWhite ${
                 isSearchOpen
                   ? "slide-up md2:translate-x-0"
                   : "slide-down md2:translate-x-full"
               }`}
      >
        {whichPage === "main" && (
          <MainPage
            handlePageChange={handlePageChange}
            toggleSearch={toggleSearch}
          />
        )}
        {whichPage === "search" && (
          <SearchPage handlePageChange={handlePageChange} />
        )}
        {whichPage === "product" && (
          <ProductPage handlePageChange={handlePageChange} />
        )}
        {whichPage === "category" && (
          <CategoryProductsPage handlePageChange={handlePageChange} />
        )}

        {/* Floating View Cart Button */}
        {!isRequestOpen && (
          <div className="fixed z-[30] bottom-0 w-full left-0">
            <ViewCartButton />
            <MobileFooter showBack={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
