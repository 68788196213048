import { useState } from "react";
import { cancelOrderApi } from "../../api";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useDispatch } from "react-redux";
import { setUpdates } from "../../features/apiCartSlice";

const Accordian = ({ title, children, id, desc, items, open = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(open);

  const cancelOrder = async () => {
    const access = localStorage.getItem("accessToken");
    const orderCode = localStorage.getItem("orderCode");
    try {
      const response = await fetch(cancelOrderApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          order_code: orderCode,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        if (data.status) {
          navigate("/order-cancelled");
          dispatch(setUpdates(null))
        } else toast("Something Went Wrong!");
      } else {
        toast("Something Went Wrong!");
      }
    } catch (err) {}
  };

  return (
    <div className="scroll-smooth" id={id}>
      <button className="text-kitsaWhite font-medium flex justify-between items-center gap-3 w-full py-[13px] px-3">
        <div className="flex flex-col">
          <span className="text-xs text-left">{title}</span>
          <p className="text-start text-[10px] opacity-50">{items} items</p>
          <p className="text-[10px] opacity-50 text-left">{desc}</p>
        </div>

        <div className="flex items-center gap-2">
          {isOpen ? (
            <div
              onClick={() => setIsOpen(!isOpen)}
              className="text-xs font-bold bg-kitsaGreen text-kitsaWhite px-2 py-1 rounded-[8px] transition-all duration-500"
            >
              Hide
            </div>
          ) : (
            <div
              onClick={() => setIsOpen(!isOpen)}
              className="text-xs font-bold bg-kitsaGreen text-kitsaWhite px-2 py-1 rounded-[8px] transition-all duration-500"
            >
              View
            </div>
          )}

          <div
            onTouchStart={(e) =>
              e.currentTarget.classList.add("scale-95", "opacity-70")
            }
            onTouchEnd={(e) =>
              e.currentTarget.classList.remove("scale-95", "opacity-70")
            }
            onClick={cancelOrder}
            className="text-xs font-bold bg-kitsaGreen text-kitsaWhite px-2 py-1 rounded-[8px] transition-all duration-500"
          >
            Cancel Order
          </div>
        </div>
      </button>

      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-white px-3 ${
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="mb-[8px] font-light text-[14px] md:text-[16px] text-kitsaWhite overflow-hidden">
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordian;
