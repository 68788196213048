import { useEffect, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { toggleSearchComponent } from "../../features/searchSlice";
import { searchTermsApi } from "../../api";

const SearchBar = ({ bg }) => {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const dispatch = useDispatch();
  const [currentText, setCurrentText] = useState("");
  const [visible, setVisible] = useState(true);
  const [list, setList] = useState([]);

  const getSearchTerms = async () => {
    try {
      const response = await fetch(searchTermsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setList(data.results);
      }
    } catch {}
  };

  useEffect(() => {
    getSearchTerms();
  }, []);

  let i = 0;

  useEffect(() => {
    const textAnimation = () => {
      if (list && list.length > 0) setCurrentText(list[i].term);
      setVisible(true);
      setTimeout(() => setVisible(false), 1700);
      i = (i + 1) % list?.length;
    };
    textAnimation();
    const interval = setInterval(() => {
      textAnimation();
    }, 2000);
    return () => clearInterval(interval);
  }, [list]);

  return (
    <div
      id="search-bar"
      className={`sticky left-0 top-8 md:top-14 z-[18] px-[10px] md:px-10 ${bg} md2:px-14 lg:px-10 xl:px-12 pt-3 pb-5`}
    >
      <div
        onClick={() => {
          dispatch(toggleSearchComponent());
        }}
        className="flex items-center gap-3 bg-formisWhite rounded-[10px] px-3 h-[51px] backdrop-blur-sm"
      >
        <IoSearchOutline className="text-xl" />
        <div className="flex items-center gap-2 bg-transparent font-[500] w-full placeholder:text-formisBlack/60 outline-none">
          {/* <div>Search For</div> */}

          <div
            className={`text-[13px] font-medium leading-[-0.3px] md:text-base slider left-[-5px] ${
              visible
                ? "opacity-100 translate-y-0"
                : "opacity-0 -translate-y-full"
            } transition-all`}
          >
            {currentText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
