import { TiTick } from "react-icons/ti";
import { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../features/cartSlice";
import { addToCartApi, deleteFromCartApi } from "../../api";
import imageNA from "../../assets/Image-NA.webp";
import { setUserCartItems } from "../../features/apiCartSlice";

const ProductCard = ({ productData }) => {
  const dispatch = useDispatch();

  const userCart = useSelector((state) => state.apiCart.userCartItems);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };
  const maxLength = 250;

  const cartItem = useSelector((state) =>
    state.cartItems.items.find(
      (cartItem) => cartItem.product_id === productData.product_id
    )
  );
  const userCartItem = userCart?.find(
    (item) => item.product_id === productData.product_id
  );

  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [productQuantity, setProductQuantity] = useState(
    userCartItem?.quantity || 1
  );

  const [loading, setLoading] = useState(false);

  const handleAddToCart = () => {
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(productQuantity);
    } else dispatch(addToCart(productData));
  };

  const handleDecrement = () => {
    if (productQuantity > 1) {
      const updatedQuantity = productQuantity - 1;
      setProductQuantity(updatedQuantity);
      handleAddToCartApiCall(updatedQuantity);
    } else {
      handleDeleteApiCall();
    }
  };

  const handleReduxDecrement = () => {
    if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
    if (cartItem?.quantity > 1) {
      dispatch(decrementQuantity({ product_id: productData.product_id }));
    }
  };

  const handleIncrement = () => {
    const updatedQuantity = productQuantity + 1;
    setProductQuantity(updatedQuantity);
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(updatedQuantity);
    } else dispatch(incrementQuantity({ product_id: productData.product_id }));
  };

  const handleAddToCartApiCall = async (quantity) => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id: productData.product_id,
              quantity,
            },
          ],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to add to cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteApiCall = async () => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(deleteFromCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          product_ids: [productData.product_id],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(removeFromCart(cartItem));
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to delete:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="lg:flex justify-between gap-10 2xl:gap-14 pr-[16px]">
      <div className="rounded-[10px] max-w-[60%] mx-auto p-1 mb-5 bg-white relative shadow-md shadow-kitsaWhite">
        <img
          src={
            productData.image_url !== "NA" &&
            productData.image_url !==
              "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
              ? productData.image_url
              : "https://media-hosting.imagekit.io//a3385a43077f4e0e/Image%20Not%20Available.png?Expires=1833358862&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=h-gValwZSoRKUBi9iEHAzKOKdwPlgYLNYSi5K7sAEH2knqMt4gpgaf~GJytsoI9gQemKj-5mIaJ4SPminI9Ts4j4esZ4j-udW3LgKjxS5Vn7goq6Z4bhS~5hzKy4uzSjttW1KAoemJvrM3em5fiBlImWlXQ6lOkyt79rhvtE11KwbRFH2w2pbdXPemroPpZxgHLRAAJ6EEsWL-psJxPosRF6LZz5giAWuUNGeem~P0IieT38Qlzvb6~nyJ96og4-XODPBV46s6vk3amWOa20pBrPSDP0X6a7Tjwpo9vunyMapTz9U0ucJqtk~c6yn9kD-YcgMIlQtPJmOG8AbIdtSA__"
          }
          loading="lazy"
          alt={productData.name}
          className="aspect-square rounded-[10px] mx-auto w-full h-fit"
        />
        <div className="font-[MestizaSans] zigzag-shape rounded-tl-[inherit] pt-2 pb-4 lg:pb-8 px-2 lg:px-3 absolute top-0 left-0 bg-kitsaWhite">
          <div className="text-xs lg:text-sm text-formisBlack font-[600] rounded-tl-[inherit]">
            <p>
              {parseInt(
                Math.floor(productData?.discount_percentage.slice(0, -1))
              )}
              %
            </p>{" "}
            <p>OFF</p>
          </div>
        </div>
      </div>

      <div className="flex-1 pt-[20px]">
        <p className="font-medium mb-3 tracking-wide capitalize">
          {productData.packaging}
        </p>
        <h2 className="text-2xl md:text-[40px] 3xl:text-[43px] font-bold mb-5 leading-none">
          {productData.name}
        </h2>

        <div className="mb-2">
          <div className="flex flex-col md:flex-row md:gap-10 md:items-center font-[500]">
            <div className="text-base flex gap-2">
              <p>₹{productData.final_price.toFixed(2)}/-</p>
              <s className="text-xs">₹{productData.mrp.toFixed(2)}/-</s>
            </div>
          </div>
        </div>

        <div className="text-lg">
          <div className="mb-2">
            <p className="inline">
              About:{" "}
              <span className="font-[MestizaSans]">
                {isExpanded
                  ? productData.introduction
                  : productData.introduction.length > maxLength
                  ? productData.introduction.slice(0, maxLength) + "..."
                  : productData.introduction}
              </span>
            </p>
            {productData.introduction.length > maxLength && (
              <button
                onClick={toggleDescription}
                className="inline underline px-2"
              >
                {isExpanded ? "Read Less" : "Read More"}
              </button>
            )}
          </div>

          <p className="mb-2 text-base">
            Manufacturer:{" "}
            <span className="font-[MestizaSans]">
              {productData.manufacturer}
            </span>
          </p>
          <p className="text-base">
            Salt Composition:{" "}
            <span className="font-[MestizaSans]">
              {productData.salt_composition !== "NA"
                ? productData.salt_composition
                : productData.ingredients}
            </span>
          </p>
        </div>

        {isAuthenticated !== "true" ? (
          <div className="flex items-center gap-4 mt-5 mb-4">
            {cartItem && (
              <div className="flex items-center justify-between px-3 py-3 rounded-full w-full border border-kitsaWhite bg-formisBlack">
                <div className="pr-2 text-xl cursor-pointer">
                  <IoIosArrowBack onClick={handleReduxDecrement} />
                </div>
                <input
                  type="number"
                  readOnly
                  className="w-8 text-center bg-transparent outline-none"
                  value={cartItem?.quantity || 0}
                />
                <div className="pl-2 text-xl cursor-pointer">
                  <IoIosArrowForward onClick={handleIncrement} />
                </div>
              </div>
            )}

            <button
              className={`${
                cartItem && "hidden"
              } flex-1 rounded-full button-font font-[500] border-2 border-formisBlack bg-formisBlack text-kitsaWhite p-3 lg:p-4
                        lg:hover:bg-transparent lg:hover:text-formisBlack transition-colors duration-300`}
              onClick={() => dispatch(addToCart(productData))}
            >
              Add to cart
            </button>
          </div>
        ) : (
          <div className="flex items-center gap-4 mt-5 mb-4">
            {userCartItem && (
              <div className="flex items-center justify-between px-3 py-3 rounded-full w-full border border-kitsaWhite bg-formisBlack">
                <div className="pr-2 text-xl cursor-pointer">
                  <IoIosArrowBack onClick={handleDecrement} />
                </div>
                {!loading ? (
                  <input
                    type="number"
                    readOnly
                    className="w-8 text-center bg-transparent outline-none"
                    value={userCartItem?.quantity || 0}
                  />
                ) : (
                  <div className="w-8">
                    <div className="loader2 w-8"></div>
                  </div>
                )}
                <div className="pl-2 text-xl cursor-pointer">
                  <IoIosArrowForward onClick={handleIncrement} />
                </div>
              </div>
            )}

            <button
              className={`${
                userCartItem && "hidden"
              } flex-1 rounded-full button-font font-[500] xl:font-[600] border-2 border-kitsaWhite bg-kitsaWhite text-formisBlack p-3 lg:p-4
                        lg:hover:bg-transparent lg:hover:text-formisBlack transition-colors duration-300`}
              onClick={handleAddToCart}
            >
              Add to cart
            </button>
          </div>
        )}

        <div className="bg-formisGrey/50 px-6 py-5 rounded-[10px] mt-10">
          <div className="flex gap-2">
            <div>
              <TiTick className="bg-formisBlack text-formisBlack15 rounded-full text-formisWhite" />
            </div>
            <div>
              <p className="text-sm tracking-tight font-medium leading-none mb-1">
                {productData.storage}
              </p>
              <p className="text-sm tracking-tight font-medium leading-none mb-1">
                Marketer: <b>{productData.marketer_details}</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
