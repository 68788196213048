import { useDispatch, useSelector } from "react-redux";
import { toggleIsAddressSliderOpen } from "../features/mapSlice";
import LocationPage from "./Address Slider Components/LocationPage";
import Main from "./Address Slider Components/Main";

import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import SavedAddresses from "./Address Slider Components/SavedAddresses";
import SearchAddress from "./Address Slider Components/SearchAddress";
import { useEffect } from "react";

const AddressSlider = () => {
  const dispatch = useDispatch();
  const { isAddressSliderOpen, addressStep } = useSelector(
    (state) => state.googleMapLocation
  );

  useGSAP(() => {
    if (addressStep !== null) {
      gsap.to("#main-location-page", {
        translateX: "-100px",
        duration: 0.25,
      });
    }
    if (addressStep == null) {
      gsap.to("#main-location-page", {
        translateX: 0,
      });
    }
  }, [addressStep]);

  useEffect(() => {
    if (isAddressSliderOpen) {
      document.documentElement.style.overflow = "hidden";
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    }
    return () => {
      document.documentElement.style.overflow = "auto";
      document.body.style.overflow = "auto";
    };
  }, [isAddressSliderOpen]);

  return (
    <div className="text-formisBlack">
      <div
        className={`transition-opacity duration-500 ${
          isAddressSliderOpen
            ? "bg-formisBlack/30 z-20"
            : "bg-transparent -z-50"
        } fixed inset-0 cross-cursor`}
        onClick={() => dispatch(toggleIsAddressSliderOpen(false))}
      />
      <div
        id="address-slider-main"
        className={`fixed md2:top-0 md2:right-0 gradient-bg z-[31] rounded-t-[20px] md2:rounded-t-none md2:rounded-tl-[20px] md2:rounded-l-[20px] w-screen h-[100dvh] md2:w-[70vw] lg:w-[50vw] xl:w-[45vw] 2xl:w-[40vw] 3xl:w-[35vw] md2:h-screen
               transition-transform duration-500 flex flex-col md2:overflow-x-hidden ${
                 isAddressSliderOpen
                   ? "slide-up md2:translate-x-0"
                   : "slide-down md2:translate-x-full"
               }`}
      >
        <Main />

        <div
          className={`overflow-scroll absolute bottom-0 left-0 gradient-bg transition-all duration-300 ease-out h-full w-full text-formisBlack ${
            addressStep === 1 ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <LocationPage />
        </div>
        <div
          className={`overflow-scroll absolute bottom-0 left-0 gradient-bg transition-all duration-300 ease-out h-full w-full text-formisBlack ${
            addressStep === 2 ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <SavedAddresses />
        </div>
        <div
          className={`overflow-scroll absolute bottom-0 left-0 gradient-bg transition-all duration-300 ease-out h-full w-full text-formisBlack ${
            addressStep === 3 ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <SearchAddress />
        </div>
      </div>
    </div>
  );
};

export default AddressSlider;
