import FAQ from "../components/FAQ/FAQ";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Topbar from "../components/Topbar";
import SearchBar from "../components/Main Page Components/SearchBar";
import SplashPage from "../components/SplashPage";
import {
  categoryProductsApi,
  featuredSectionsApi,
  guestTokenApi,
} from "../api";
import { useEffect, useRef, useState } from "react";
import MobileFooter from "../components/MobileFooterMenu";
import CategoriesSlider from "../components/Main Page Components/CategoriesSlider";

import CategoryMain from "../components/Main Page Components/CategoryMain";

import UploadPrescription from "../components/Main Page Components/UploadPrescription";
import Search from "../components/Search";
import Profile from "../components/Profile";
import AddressSlider from "../components/AddressSlider";
import SurgeSlider from "../components/SurgeSlider";
import ViewCartButton from "../components/Footer Menu Buttons/ViewCartButton";
import useCartDetails from "../hooks/useCartDetails";
import SponsorCard from "../components/Main Page Components/SponsorCard";
import Brand from "../components/Main Page Components/Brand";
import { useNavigate } from "react-router-dom";
import EndText from "../components/EndText";
import InstallPWAPopup from "../components/Main Page Components/InstallPWA";
import MustTry from "../components/Main Page Components/MustTry";

import {
  mustTryData,
  brandCardBrandData,
  brandCardProductData,
  diabetesData,
} from "../components/Main Page Components/cardsData";

const categories = [
  "Skin, hair & oral care",
  "Stomach care",
  "Sexual wellness",
  "Feminine Care",
  "Fitness Supplements",
  "Vitamins & Nutrition",
  "Ayurvedic Supplements",
  "Nutritional Drinks",
  "Health Conditions",
  "Wellness",
  "Pain Relief",
  "Skin Care",
  "Personal Care",
  "Supports & Braces",
  "Healthcare Devices",
  "Elderly Care",
  "Diabetes",
  "Monitoring Devices",
  "Hair Care",
  "First Aid",
  "Baby Care",
];

// const categories = [
//   "Skin, hair & oral care",
//   "Stomach care",
//   "Sexual wellness",
//   "Feminine Care",
//   "Fitness Supplements",
//   "Vitamins & Nutrition",
//   "Ayurvedic Supplements",
//   "Nutritional Drinks",
//   "Health Conditions",
//   "Wellness",
//   "Pain Relief",
//   "Skin Care",
//   "Personal Care",
//   "Supports & Braces",
//   "Healthcare Devices",
//   "Elderly Care",
//   "Diabetes",
//   "Monitoring Devices",
//   "Hair Care",
//   "First Aid",
//   "Baby Care",
//   "Protect+ Essentials",
//   "Immunity Boosters",
//   "Healthy Snacks",
//   "Homeopathy",
//   "Eye & Ear Care",
//   "Pet Care",
//   "Herbal Juice",
//   "Cold, Cough & Fever",
//   "Rehydration Beverages",
//   "Fever & Headache",
//   "Skin Infection",
// ];

const categoryNames = [
  "Proveda Kit",
  "Everyday Health Essentials",
  "Sexual Health Essentials",
  "Sports Nutrition",
  "Lifestyle Essentials",
  "Energy Drinks",
  "Travel Essentials",
  "Smart Monitoring Devices",
  "Health Vital Trackers",
  "Kids Essentials",
  "Intimate Hygiene Essentials",
  "Hair and Skin Care Essentials",
  "Ayurvedic Essentials",
  "Homeopathy Essentials",
  "Health Snacks",
  "Eye Care",
  "Medicines and Treatments",
  "Cough Cold and Flu",
  "Women's Wellness",
  "Men's Wellness",
  "Household Essentials",
];

const Main = () => {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [showUploadFlow, setShowUploadFlow] = useState();
  const [showSplash, setShowSplash] = useState(false);

  const [topDeals, setTopDeals] = useState();
  const [results, setResults] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const lastCategoryRef = useRef(null);
  const isEffectCalled = useRef(false);
  useCartDetails();

  // useEffect(() => {
  //   console.log("top deals", topDeals);
  //   console.log("results", results);
  // }, [topDeals, results]);

  //GUEST TOKEN IF USER IS NOT AUTHENTICATED
  const getGuestToken = async () => {
    try {
      const response = await fetch(guestTokenApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("guestToken", data.guest_token);
      }
    } catch {}
  };

  //MAINTAIN SESSION STORAGE TO NOT DISPLAY SPLASH PAGE REPEATEDLY ON NAVIGATION
  useEffect(() => {
    if (!sessionStorage.getItem("hasSeenSplash")) {
      setShowSplash(true);
      sessionStorage.setItem("hasSeenSplash", "true");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated !== "true") getGuestToken();
  }, []);

  const fetchCategory = async () => {
    setIsCategoriesLoading(true);
    try {
      const nextCategories = categories.slice(
        results.length,
        results.length + 5
      );
      const responses = await Promise.all(
        nextCategories.map(async (category) => {
          const response = await fetch(categoryProductsApi + category);
          if (response.ok) {
            const data = await response.json();
            // console.log("Fetched for category " + category);
            return { category, data };
          } else {
            throw new Error(`Failed to fetch for parameter: ${category}`);
          }
        })
      );

      setResults((prevResults) => [...prevResults, ...responses]);
    } catch (err) {
      console.error("ERROR IN CATEGORIES FETCHING", err);
    } finally {
      setIsCategoriesLoading(false);
    }
  };

  const featuredBlogs = async () => {
    try {
      const response = await fetch(featuredSectionsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setFeatured(data);
      }
    } catch {}
  };

  const fetchTopDeals = async () => {
    try {
      const response = await fetch(
        `https://kitsa.co.in/api/v1/medicines/category/Cold, Cough & Fever`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setTopDeals(data);
      }
    } catch {}
  };

  useEffect(() => {
    if (isEffectCalled.current === false) {
      fetchTopDeals();
      featuredBlogs();
      fetchCategory();
    }
    return () => (isEffectCalled.current = true);
  }, []);

  useEffect(() => {
    if (!lastCategoryRef.current || results.length === 0) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && results.length < categories.length)
          fetchCategory();
      },
      { root: null, threshold: 1 }
    );

    observer.observe(lastCategoryRef.current);

    return () => {
      observer.disconnect();
    };
  }, [results]);

  return (
    <div
      id="main-page"
      className="h-screen overflow-x-hidden overflow-y-scroll scrollbar-hide gradient-bg"
    >
      {showSplash && <SplashPage />}

      <Topbar />
      <div className="pt-12 md:pt-14">
        <Header />
      </div>

      <SearchBar
        bg="bg-formisBlack"
        showUpload={true}
        setShowUploadFlow={setShowUploadFlow}
      />
      <div className="pb-8 bg-formisBlack relative z-10">
        <div className="flex items-center justify-center gap-5">
          <p className="text-kitsaWhite font-medium text-sm">
            Or simply order using your Rx
          </p>
          <button
            onClick={() => {
              if (isAuthenticated === "true") setShowUploadFlow(true);
              else navigate("/login");
            }}
            onTouchStart={(e) =>
              e.currentTarget.classList.add("scale-95", "opacity-70")
            }
            onTouchEnd={(e) =>
              e.currentTarget.classList.remove("scale-95", "opacity-70")
            }
            className="bg-kitsaGreen font-medium text-kitsaWhite text-sm border border-kitsaWhite/30 px-4 py-[2px] rounded-[10px] transition-all duration-150"
          >
            Upload Here
          </button>
        </div>
      </div>

      <div className="bg-formisBlack rounded-b-[10px] pb-1 mb-7 shadow-white">
        <div className="mb-8">
          <CategoriesSlider />
        </div>
        {topDeals && (
          <CategoryMain result={topDeals} name={"Cold, Cough & Fever"} />
        )}
      </div>

      <div className="mb-4">
        <SponsorCard featured={featured} name="Featured Articles" />
      </div>
      <div className="mb-4">
        <MustTry name="Must Try" productData={mustTryData} />
      </div>

      <div className="">
        <div>
          {results.slice(0, 5).map((res, index) => (
            <CategoryMain key={index} result={res.data} name={res.category} />
          ))}

          <div
            ref={results.length === 5 ? lastCategoryRef : null}
            className="pt-7 pb-14"
          >
            <Brand name="Product of The Week" cardData={brandCardProductData} />
          </div>

          {results.slice(5, 10).map((res, index) => (
            <CategoryMain
              key={index + 5}
              result={res.data}
              name={res.category}
            />
          ))}

          <div
            ref={results.length === 10 ? lastCategoryRef : null}
            className="pt-7 pb-14"
          >
            <Brand name="Brand of The Week" cardData={brandCardBrandData} />
          </div>

          {results.slice(10, 15).map((res, index) => (
            <CategoryMain
              key={index + 10}
              result={res.data}
              name={res.category}
            />
          ))}

          <div
            ref={results.length === 15 ? lastCategoryRef : null}
            className="pt-7 pb-14"
          >
            <SponsorCard featured={featured} name="Featured Articles" />
            <MustTry name="Diabetes Care" productData={diabetesData} />
          </div>

          {results.slice(15, 20).map((res, index) => (
            <CategoryMain
              key={index + 15}
              result={res.data}
              name={res.category}
            />
          ))}
          <div
            ref={results.length === 20 ? lastCategoryRef : null}
            className="pt-7 pb-14"
          >
            <SponsorCard featured={featured} name="Featured Articles" />
            <MustTry name="Must Try" productData={mustTryData} />
          </div>

          {results.slice(20, 21).map((res, index) => (
            <CategoryMain
              key={index + 15}
              result={res.data}
              name={res.category}
            />
          ))}

          {isCategoriesLoading && (
            <div className="w-full pt-4 bg-formisBlack mx-auto flex items-center justify-center">
              <div className="loader4"></div>
            </div>
          )}
        </div>

        <EndText />
      </div>

      <div className="pt-10">
        <FAQ />
      </div>

      <Footer />
      <div className="fixed z-[30] bottom-0 w-full left-0">
        <ViewCartButton />
        <MobileFooter />
      </div>
      <UploadPrescription
        showUploadFlow={showUploadFlow}
        setShowUploadFlow={setShowUploadFlow}
      />
      <Search />
      <Profile />
      <AddressSlider />
      <SurgeSlider />
      <UploadPrescription
        showUploadFlow={showUploadFlow}
        setShowUploadFlow={setShowUploadFlow}
      />

      <InstallPWAPopup />
    </div>
  );
};

export default Main;

{
  /* <div>
        {results.map((res, index) => {
          const isLastCategory = index === results.length - 1;
          const shouldShowSponsorCard = (index + 1) % 10 === 0;
          const sponsorCardIndex = Math.floor(index / 10) * 4;

          return (
            <div key={index}>
              <div
                ref={
                  isLastCategory && !shouldShowSponsorCard
                    ? lastCategoryRef
                    : null
                }
              >
                <CategoryMain result={res.data} name={res.category} />
              </div>

              {shouldShowSponsorCard && (
                <div
                  className="bg-formisBlack pt-7 pb-14"
                  ref={
                    isLastCategory && shouldShowSponsorCard
                      ? lastCategoryRef
                      : null
                  }
                >
                  <SponsorCard
                    featured={featured.slice(
                      sponsorCardIndex,
                      sponsorCardIndex + 4
                    )}
                  />
                </div>
              )}
            </div>
          );
        })}
        {isCategoriesLoading && (
          <div className="w-full pt-4 bg-formisBlack mx-auto flex items-center justify-center">
            <div className="loader4"></div>
          </div>
        )}
      </div> */
}

{
  /* <div>
        {results.map((res, index) => {
          const isFirstCategory = index === 0;
          const shouldShowSponsorCard =
            isFirstCategory || (index > 0 && index % 10 === 0);
          const sponsorCardIndex = Math.floor(index / 10) * 4;

          return (
            <div key={index}>
              <div
                ref={
                  index === results.length - 1 && !shouldShowSponsorCard
                    ? lastCategoryRef
                    : null
                }
              >
                <CategoryMain result={res.data} name={res.category} />
              </div>

              {shouldShowSponsorCard && (
                <div
                  className="bg-formisBlack pt-7 pb-14"
                  ref={
                    index === results.length - 1 && shouldShowSponsorCard
                      ? lastCategoryRef
                      : null
                  }
                >
                  <SponsorCard
                    featured={featured.slice(
                      sponsorCardIndex,
                      sponsorCardIndex + 4
                    )}
                  />
                </div>
              )}
            </div>
          );
        })}

        {isCategoriesLoading && (
          <div className="w-full pt-4 bg-formisBlack mx-auto flex items-center justify-center">
            <div className="loader4"></div>
          </div>
        )}
      </div> */
}

{
  /* <div className="">
        <div>
          {results.map((res, index) => {
            const isLastCategory = index === results.length - 1;
            const isBrandSection = index > 0 && index % 10 === 5;
            const isSponsorSection =
              index > 0 && index % 10 === 0 && index !== 0;
            const sponsorIndex = Math.floor(index / 10) * 2; // Pick names dynamically

            return (
              <div key={index}>
                <div ref={isLastCategory ? lastCategoryRef : null}>
                  <CategoryMain result={res.data} name={res.category} />
                </div>
                {isBrandSection && (
                  <div className="pt-7 pb-14">
                    <Brand />
                  </div>
                )}
                {isSponsorSection && (
                  <div className="pt-7 pb-14">
                    <SponsorCard
                      featured={featured}
                      name={names[sponsorIndex % names.length]}
                    />
                    <SponsorCard
                      featured={featured}
                      name={names[(sponsorIndex + 1) % names.length]}
                    />
                  </div>
                )}
              </div>
            );
          })}

          {isCategoriesLoading && (
            <div className="w-full pt-4 mx-auto flex items-center justify-center">
              <div className="loader4"></div>
            </div>
          )}
        </div>

        <EndText />
      </div> */
}
