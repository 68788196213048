import all from "../../assets/icons/all.svg";
import beauty from "../../assets/icons/beauty.svg";
import sports from "../../assets/icons/sports.svg";
import everyday from "../../assets/icons/everyday.svg";
import ayurved from "../../assets/icons/ayurved.svg";
import remedies from "../../assets/icons/remedies.svg";
import wellness from "../../assets/icons/wellness.svg";
import protect from "../../assets/icons/protect+.svg";

import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

const items = [
  { icon: all, label: "All", link: "all-products" },
  { icon: beauty, label: "Beauty", link: "/categories/Skin, hair & oral care" },
  { icon: sports, label: "Sports", link: "/categories/Fitness supplements" },
  {
    icon: everyday,
    label: "Everyday",
    link: "/categories/Vitamins & nutrition",
  },
  {
    icon: ayurved,
    label: "Ayurved",
    link: "/categories/Ayurvedic Supplements",
  },
  { icon: wellness, label: "Wellness", link: "/categories/Wellness" },
  {
    icon: remedies,
    label: "Remedies",
    link: "/categories/Cold, Cough & Fever",
  },
  { icon: protect, label: "Protect+", link: "/categories/Protect+ Essentials" },
];

const CategoriesSlider = () => {
  const navigate = useNavigate();
  return (
    <div className="px-2 md:px-10 lg:px-12 text-kitsaWhite">
      <Swiper slidesPerView={4} spaceBetween={30}>
        {items.map(({ icon, label, link }) => (
          <SwiperSlide key={label}>
            <div
              className="flex flex-col items-center"
              onClick={() => navigate(link)}
              onTouchStart={(e) =>
                e.currentTarget.classList.add("scale-95", "opacity-70")
              }
              onTouchEnd={(e) =>
                e.currentTarget.classList.remove("scale-95", "opacity-70")
              }
            >
              <img src={icon} alt={label} className="w-[46px]" />
              <p className="text-xs font-medium tracking-wider">{label}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CategoriesSlider;

// import all from "../../assets/icons/all.svg";
// import beauty from "../../assets/icons/beauty.svg";
// import sports from "../../assets/icons/sports.svg";
// import everyday from "../../assets/icons/everyday.svg";
// import ayurved from "../../assets/icons/ayurved.svg";
// import remedies from "../../assets/icons/remedies.svg";
// import wellness from "../../assets/icons/wellness.svg";
// import protect from "../../assets/icons/protect+.svg";

// import back from "../../assets/icons/back-peach.svg";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { toggleSearchComponent } from "../../features/searchSlice";

// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/autoplay";

// const items = [
//   { icon: all, label: "All", link: "all-products" },
//   { icon: beauty, label: "Beauty", link: "/categories/Skin, hair & oral care" },
//   { icon: sports, label: "Sports", link: "/categories/Fitness supplements" },
//   {
//     icon: everyday,
//     label: "Everyday",
//     link: "/categories/Vitamins & nutrition",
//   },
//   {
//     icon: ayurved,
//     label: "Ayurved",
//     link: "/categories/Ayurvedic Supplements",
//   },
//   { icon: wellness, label: "Wellness", link: "/categories/Wellness" },
//   {
//     icon: remedies,
//     label: "Remedies",
//     link: "/categories/Cold, Cough & Fever",
//   },
//   { icon: protect, label: "Protect+", link: "/categories/Protect+ Essentials" },
// ];

// const CategoriesSlider = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   return (
//     <div className="pt-3 pb-7 rounded-b-[25px] shadow-peachCustom2">
//       <div className="px-4 md:px-10 lg:px-12 pb-[51px] text-formisWhite">
//         <Swiper slidesPerView={4} spaceBetween={20}>
//           {items.map(({ icon, label, link }) => (
//             <SwiperSlide key={label}>
//               <div
//                 className="flex flex-col items-center gap-1"
//                 onClick={() => navigate(link)}
//                 onTouchStart={(e) =>
//                   e.currentTarget.classList.add("scale-95", "opacity-70")
//                 }
//                 onTouchEnd={(e) =>
//                   e.currentTarget.classList.remove("scale-95", "opacity-70")
//                 }
//               >
//                 <img src={icon} alt={label} className="w-[46px]" />
//                 <p className="text-xs font-bold tracking-wider font-[MestizaSans]">
//                   {label}
//                 </p>
//               </div>
//             </SwiperSlide>
//           ))}
//         </Swiper>
//       </div>

//       <button
//         className="text-formisWhite font-bold text-xs flex items-center gap-8 mx-auto"
//         onClick={() => dispatch(toggleSearchComponent())}
//         onTouchStart={(e) =>
//           e.currentTarget.classList.add("scale-95", "opacity-70")
//         }
//         onTouchEnd={(e) =>
//           e.currentTarget.classList.remove("scale-95", "opacity-70")
//         }
//       >
//         View all products
//         <img
//           src={back}
//           alt=">"
//           className="rotate-180 w-5 transition-all duration-150"
//         />
//       </button>
//     </div>
//   );
// };

// export default CategoriesSlider;
