import { useDispatch } from "react-redux";
import imageNA from "../../assets/Image-NA.webp";
import discount from "../../assets/icons/discount.svg";
import { setShowComingSoon } from "../../features/slidersSlice";

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  return (
    <div className="flex gap-4 justify-between">
      <div className="flex gap-4">
        <div className="relative shrink-0 h-[77px] w-[78px] bg-white p-1 rounded-[10px]">
          <img
            src={
              product?.product.primary_image_url !== "NA" &&
              product?.product.primary_image_url !==
                "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? product?.product.primary_image_url
                : "https://media-hosting.imagekit.io//a3385a43077f4e0e/Image%20Not%20Available.png?Expires=1833358862&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=h-gValwZSoRKUBi9iEHAzKOKdwPlgYLNYSi5K7sAEH2knqMt4gpgaf~GJytsoI9gQemKj-5mIaJ4SPminI9Ts4j4esZ4j-udW3LgKjxS5Vn7goq6Z4bhS~5hzKy4uzSjttW1KAoemJvrM3em5fiBlImWlXQ6lOkyt79rhvtE11KwbRFH2w2pbdXPemroPpZxgHLRAAJ6EEsWL-psJxPosRF6LZz5giAWuUNGeem~P0IieT38Qlzvb6~nyJ96og4-XODPBV46s6vk3amWOa20pBrPSDP0X6a7Tjwpo9vunyMapTz9U0ucJqtk~c6yn9kD-YcgMIlQtPJmOG8AbIdtSA__"
            }
            loading="lazy"
            alt="image"
            className="rounded-[10px]"
          />
          <div className="h-[23px] w-[19px] absolute -top-[7px] z-10 -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute -top-1 -left-[2.5px] h-[28px] w-[23px] flex items-center justify-center">
              <p className="text-[8px] text-formisBlack font-bold leading-none">
                {parseInt(product.discount_percentage)}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        <div className="shrink">
          <p className="text-[11px] font-medium mb-1">
            {product?.product.name}
          </p>
          <div className="flex items-center gap-1">
            <div className="bg-kitsaWhite rounded-full">
              <span className="capitalize text-[10px] font-bold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-kitsaWhite text-formisBlack border border-kitsaWhite">
                {product?.product.packaging}
              </span>
            </div>
          </div>
          <button
            onClick={() => dispatch(setShowComingSoon(true))}
            className="text-[10px] font-bold text-kitsaGreen border-b border-kitsaGreen leading-none"
          >
            Add to Auto Refill Schedule
          </button>
        </div>
      </div>

      <div className="">
        <div className="bg-kitsaGreen text-kitsaWhite flex items-center justify-center w-full rounded-[8px] py-[2px] mb-4 text-sm font-bold">
          {product?.quantity}
        </div>

        <div className="flex items-center gap-1 font-bold place-self-end">
          <p className="text-[12px]">₹{product?.selling_price_at_time}</p>
          <span className="text-[10px]">
            <s>₹{product?.product.mrp}</s>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
